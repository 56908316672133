import React, { useEffect } from "react";
import "./Sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import { ListItem, ListItemText } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

export default function SubMenu(props) {
  const location = useLocation();
  const { listItem, checkURLMatchByParts } = props;
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    let openVal = false;
    listItem.subMenus.map((subItem, i) => {
      if (location.pathname === subItem.url) {
        openVal = true;
      }
      subItem.subUrls &&
        subItem.subUrls.map((e) => {
          const splitPath = location.pathname.split("/");

          if (location.pathname === e) {
            openVal = true;
          }
          if (splitPath[3]) {
            const newPath = "/" + splitPath[1] + "/" + splitPath[2];
            if (newPath === e) {
              openVal = true;
            }
          }
        });
    });
    setOpen(openVal);
  }, [listItem, location]);
  return (
    <>
      <ListItem
        className="submenu__level"
        button
        onClick={handleClick}
        selected={
          location.pathname === listItem.url ||
          location.pathname.indexOf(listItem.url) > -1 ||
          checkURLMatchByParts(location.pathname, listItem.subUrls)
        }
      >
        <ListItemText primary={listItem.liItemName} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className="submenu_wrapper"
      >
        {listItem.subMenus?.map((subItem, i) => {
          return (
            <Link to={subItem.url} key={i} className="sidebar__navlinks">
              <ListItem
                button
                selected={
                  location.pathname === subItem.url ||
                  location.pathname.indexOf(subItem.url) > -1 ||
                  checkURLMatchByParts(location.pathname, subItem.subUrls)
                }
              >
                <ListItemText
                  primary={subItem.liItemName}
                  className="subsidebar__navlinks-title"
                />
              </ListItem>
            </Link>
          );
        })}
      </Collapse>
    </>
  );
}
