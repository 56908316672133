import createReducer from '../../../utils/createReducer';

import { FETCH_HEADER_LINKS, SET_HEADER_LINKS } from '../../constants/common/Header.type';

import { API_START, API_END, RESET_STATE } from '../../constants/common/Api.Types';

export const initialState = {
    headerLinks: [],
    isLoading: false
}

export const reducerObj = {
    [SET_HEADER_LINKS]: (prevState, payload) => (
        {
            headerLinks: payload.result
        }
    ),
    	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => {
		if(payload === FETCH_HEADER_LINKS){
            return { 
                isLoadingData : true
            }
        }
	},
	[API_END]: (prevState, payload) => {
		if(payload === FETCH_HEADER_LINKS){
            return { 
                isLoadingData : false
            }
        }
	}
}

const HeaderReducer = createReducer(initialState, reducerObj);

export default HeaderReducer;