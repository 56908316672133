export const SET_HOTEL_POLICY_LIST = "SET_HOTEL_POLICY_LIST";
export const UPDATE_HOTEL_POLICY_LIST = "UPDATE_HOTEL_POLICY_LIST";
export const COPY_HOTEL_POLICY_SUCCESS = "COPY_HOTEL_POLICY_SUCCESS";
export const DELETE_HOTEL_POLICY_SUCCESS = "DELETE_HOTEL_POLICY_SUCCESS";
export const CHANGE_STATUS_HOTEL_POLICY_SUCCESS =
    "CHANGE_STATUS_HOTEL_POLICY_SUCCESS";
export const ACTION_HOTEL_POLICY_SUCCESS = "ACTION_HOTEL_POLICY_SUCCESS";
export const GET_HOTEL_POLICY_RULE = "GET_HOTEL_POLICY_RULE";
export const DELETE_HOTEL_POLICY_RULE = "DELETE_HOTEL_POLICY_RULE";
export const DELETE_HOTEL_LOCATION_RULE = "DELETE_HOTEL_LOCATION_RULE";
export const EDIT_DEFAULT_LOCATION_RULE = "EDIT_DEFAULT_LOCATION_RULE";
export const EDIT_DEFAULT_LOCATION_RULE_SUCCESS =
    "EDIT_DEFAULT_LOCATION_RULE_SUCCESS";
// export const EDIT_DEFAULT_LOCATION_RULE = "EDIT_DEFAULT_LOCATION_RULE";
