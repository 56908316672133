const GTMService = (function () {
    function _init(__res) {
        console.log("coming2");
        // you have to provide user information from your store

        const userinfo = {
            username: __res?.userName,
            email: __res?.email,
            first_name: __res?.firstName,
            last_name: __res?.lastName,
            role: __res?.role,
            client_id: __res?.clientId,
            user_id: __res?.userId,
            company_name: __res?.companyName,
            usm_company_id: __res?.usm_company_id,
        };
        window.dataLayer.push(userinfo);
        // console.log(gtm__obj,"dev")
        window.dataLayer.push({ event: "user-data-set-2" });
    }
    return {
        init: _init,
    };
})();

export default GTMService;
