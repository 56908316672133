const LocalStorageService = (function() {
    let __service;

    function __getService() {
        if (!__service) {
            __service = this;
            return __service;
        }
    }

    /**---set token in localStorage */
    function __setAccessToken (token) {
        window.localStorage.removeItem('il_travel_access_token');
        localStorage.setItem('il_travel_access_token', token)
    }

    /**---set token in localStorage */
    function __getCompanyId () {
        if(localStorage.getItem('uui_il_company')){
            return localStorage.getItem('uui_il_company');
        } else {
            return false;
        }
    }

    /**---get token from localStorage */
    function __getAccessToken (token) {
        if (localStorage.getItem('il_travel_access_token')) {
            return localStorage.getItem('il_travel_access_token');
        } else {
            return false;
        }
    }

    /**---get token from localStorage */
    function __removeAccessToken () {
        localStorage.removeItem('il_travel_access_token')
    }

	/**---set role in localStorage */
    function __setRole (role) {
        window.localStorage.removeItem('il_travel_role');
        localStorage.setItem('il_travel_role', role)
    }

    /**---get role from localStorage */
    function __getRole () {
        if (localStorage.getItem('il_travel_role')) {
            return localStorage.getItem('il_travel_role');
        } else {
            return false;
        }
    }

    /**---get role from localStorage */
    function __getUUIRole () {
        if (localStorage.getItem('uui_il_role')) {
            return localStorage.getItem('uui_il_role');
        } else {
            return false;
        }
    }

    /**---get role from localStorage */
    function __removeRole () {
        localStorage.removeItem('il_travel_role')
    }

	// Removes all token
    function __removeAllToken () {
        localStorage.clear()
    }

    function __getCompanyToken (token) {
        if (localStorage.getItem('uui_il_company')) {
            return localStorage.getItem('uui_il_company');
        } else {
            return false;
        }
    }

    function __getUSMRole () {
        if (localStorage.getItem('uui_il_role')) {
            return localStorage.getItem('uui_il_role');
        } else {
            return false;
        }
    }

    return {
        getService: __getService,
        setAccessToken: __setAccessToken,
        getAccessToken: __getAccessToken,
        removeAccessToken: __removeAccessToken,
        setRole: __setRole,
        getRole: __getRole,
        removeRole: __removeRole,
		removeAllToken: __removeAllToken,
        getCompanyId: __getCompanyId,
        getUUIRole: __getUUIRole,
        getCompanyToken: __getCompanyToken,
        getUSMRole:__getUSMRole
    }
})();


export default LocalStorageService;