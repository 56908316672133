import {
	CHANGE_ORDER_POLICY_RULE,
	CHANGE_STATUS_POLICY_RULE,
	COPY_POLICY_RULE,
	DELETE_POLICY_RULE,
	FETCH_POLICY_LIST,
} from "../../../../constants/IL_Admin/PolicyEditor/List/CommonPolicyList.types";
import {
	SET_POLICY_LIST,
	ACTION_POLICY_SUCCESS,
	FETCH_RULE_STATUS_CHANGE,
	RESET_POLICY_LIST
} from "../../../../constants/IL_Admin/PolicyEditor/List/FlightPolicyList.types";
import createReducer from "../../../../../utils/createReducer";
import {
	API_START,
	API_END,
	API_ERROR,
} from "../../../../constants/common/Api.Types";

const initialState = {
	isLoading: false,
	client: null,
	rules: null,
	isShowTable: false,
	isError: false,
	errorMsg: "",
	ruleCopying: false,
	ruleCopied: false,
	ruleDeleting: false,
	ruleDeleted: false,
	statusChanging: false,
	statusChanged: false,
	orderChanging: false,
	orderChanged: false,
	fetchRules: true,
};

export const reducerObj = {
	[API_START]: (prevState, payload) => {
		if (payload === FETCH_POLICY_LIST) {
			return {
				rules: null,
				isError: false,
				isLoading: true,
				ruleDeleted: false,
				ruleCopied: false,
				statusChanged: false,
				orderChanged: false,
			};
		}
		if (payload === COPY_POLICY_RULE) {
			return {
				isError: false,
				ruleCopying: true,
				ruleCopied: false,
			};
		}
		if (payload === DELETE_POLICY_RULE) {
			return {
				isError: false,
				ruleDeleting: true,
				ruleDeleted: false,
			};
		}
		if (payload === CHANGE_STATUS_POLICY_RULE) {
			return {
				isError: false,
				statusChanging: true,
				statusChanged: false,
			};
		}
		if (payload === CHANGE_ORDER_POLICY_RULE) {
			return {
				isError: false,
				orderChanging: true,
				orderChanged: false,
			};
		}
	},
	[API_END]: (prevState, payload) => {
		if (payload === FETCH_POLICY_LIST) {
			return {
				isLoading: false,
				fetchRules: false,
			};
		}
		if (payload === COPY_POLICY_RULE) {
			return {
				ruleCopying: false,
				ruleCopied: true,
			};
		}
		if (payload === DELETE_POLICY_RULE) {
			return {
				ruleDeleting: false,
				ruleDeleted: true,
			};
		}
		if (payload === CHANGE_STATUS_POLICY_RULE) {
			return {
				statusChanging: false,
				statusChanged: true,
			};
		}
		if (payload === CHANGE_ORDER_POLICY_RULE) {
			return {
				orderChanging: false,
				orderChanged: true,
			};
		}
	},
	[API_ERROR]: (prevState, payload) => {
		return {
			isError: true,
			errorMsg: payload,
		};
	},
	[SET_POLICY_LIST]: (prevState, payload) => {
		return {
			isShowTable: true,
			rules: payload,
		};
	},
	[ACTION_POLICY_SUCCESS]: () => {
		return {
			fetchRules: true,
			isShowTable: false,
		};
	},
	[FETCH_RULE_STATUS_CHANGE]: () => {
		return {
			fetchRules: true,
		};
	},
	[RESET_POLICY_LIST]: () => {
		return {
			isLoading: false,
			client: null,
			rules: null,
			isShowTable: false,
			isError: false,
			errorMsg: "",
			ruleCopying: false,
			ruleCopied: false,
			ruleDeleting: false,
			ruleDeleted: false,
			statusChanging: false,
			statusChanged: false,
			orderChanging: false,
			orderChanged: false,
			fetchRules: true,
		};
	},
};

const FlightPolicyListReducer = createReducer(initialState, reducerObj);

export default FlightPolicyListReducer;
