import {
    SET_SUGGESTION,
    SET_RECOMMENDATION,
    SET_CHART_DATA,
    UPDATE_RECOMMENDATION
} from '../../constants/Mastermind/Mastermind.types';

// import new reducer
import createReducer from "../../../utils/createReducer";

const initialState = {
    suggestion : [],
    recommendations : [],
    chartData : []
}

export const reducerObj = {
    [SET_SUGGESTION]: (prevState, payload) => ({
        suggestion: payload
    }),

    [SET_RECOMMENDATION]: (prevState, payload) => ({
        recommendations: payload
    }),

    [UPDATE_RECOMMENDATION]: (prevState, payload) => {
        const reccommendation = prevState.recommendations;
        reccommendation.map((recc)=>{
            if(recc.id === payload.id){
                recc = payload;
            }
        });
        return {...prevState, reccommendations:reccommendation};
    },

    [SET_CHART_DATA]: (prevState, payload) => ({
        chartData: payload
    })
}

const MastermindReducer = createReducer(initialState, reducerObj);

export default MastermindReducer;