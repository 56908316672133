import {
    HOTEL_FETCH_RULE_SINGLE,
    HOTEL_SUCCESS_FETCH_RULE_SINGLE,
    HOTEL_SUBMIT_POLICY,
    HOTEL_SUBMIT_POLICY_SUCCESS,
    RESET_HOTEL_STATE,
    HOTEL_SUBMIT_POLICY_FAILURE,
} from "../../../../constants/IL_Admin/PolicyEditor/Single/HotelPolicySingle.types";
import createReducer from "../../../../../utils/createReducer";
import { API_START, API_END } from "../../../../constants/common/Api.Types";

const initialState = {
    isLoading: false,
    isSubmitting: false,
    rule: {},
    ruleSubmitted: null,
    rule_id: null,
};

export const reducerObj = {
    [API_START]: (prevState, payload) => {
        if (payload === HOTEL_FETCH_RULE_SINGLE) {
            return {
                isLoading: true,
            };
        }
        if (payload === HOTEL_SUBMIT_POLICY) {
            return {
                isSubmitting: true,
            };
        }
    },
    [API_END]: (prevState, payload) => {
        if (payload === HOTEL_FETCH_RULE_SINGLE) {
            return {
                isLoading: false,
            };
        }
        if (payload === HOTEL_SUBMIT_POLICY) {
            return {
                isSubmitting: false,
            };
        }
    },
    [HOTEL_SUCCESS_FETCH_RULE_SINGLE]: (prevState, payload) => {
        return {
            ruleSubmitted: null,
            rule: payload.result,
        };
    },
    [HOTEL_SUBMIT_POLICY_SUCCESS]: (prevState, payload) => {
        return {
            ruleSubmitted: true,
            rule_id: payload.rule_id,
            success_msg: payload.message,
        };
    },
    [HOTEL_SUBMIT_POLICY_FAILURE]: (prevState, payload) => {
        return {
            ruleSubmitted: false,
            error_msg: payload.message,
        };
    },
    [RESET_HOTEL_STATE]: () => {
        return {
            ruleSubmitted: null,
            rule: {},
        };
    },
};

const HotelPolicySingleReducer = createReducer(initialState, reducerObj);

export default HotelPolicySingleReducer;
