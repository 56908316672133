import {
    GET_CUSTOM_FIELD,
    GET_CUSTOM_DIMENSION_CONFIG,
    GETONE_OPTIONS,
    GETONE_CUSTOM_DIMENSION,
    ADD_OPTION_FOR_SELECT,
    CLEAN_UP,
    UPDATE_OPTION_FOR_SELECT
} from "../../../../constants/IL_Admin/AllSettings";

const initialState = {
    customOne: {
        field_name: "",
        field_type: "text",
        status: false,
        mandatory: false,
    },
    options: [],

    customDimension: {
        status: false,
        results: [],
    },

    config: {
        maximumCustomDimension: 10
    }
};

const CustomFieldsReducer = function reducerObj(
    state = initialState,
    action = {}
) {
    const { type, payload } = action;
    switch (type) {
        case GET_CUSTOM_DIMENSION_CONFIG:
            return {
                ...state,
                config: {
                    ...state.config,
                    maximumCustomDimension: payload.result.maximum_config
                }
            }
        case GET_CUSTOM_FIELD:
            return {
                ...state,
                customDimension: {
                    status: payload.status,
                    results: payload.result,
                },
            };
        case GETONE_CUSTOM_DIMENSION:
            return {
                ...state,
                customOne: {
                    ...state.customOne,
                    field_name: payload.result[0].field_name,
                    field_type: payload.result[0].field_type,
                    status: payload.result[0].status,
                    mandatory: payload.result[0].mandatory,
                },
                options: payload.result[0].options,
            };

        case GETONE_OPTIONS:
            return {
                ...state,
                options: payload.results,
            };

        case ADD_OPTION_FOR_SELECT:
            return {
                ...state,
                options: [...state.options, payload.result],
            };

        case UPDATE_OPTION_FOR_SELECT:
            return {
                ...state,
                options: state.options.map(option =>{
                    if(option.id === payload.result.id){
                        option = payload.result
                    }
                    return option
                }),
            }
        case CLEAN_UP:
            return {
                ...state,
                customOne: {
                    field_name: "",
                    field_type: "text",
                    status: false,
                    mandatory: false,
                    options: [],
                },

                customDimension: {
                    status: false,
                    results: [],
                },
            };

        default:
            return state;
    }
};
export default CustomFieldsReducer;
