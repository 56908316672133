import URLs from "../../utils/urls/routeUrls";
import { appRoutes } from "../../utils/appRoutes";

export const listItems = [
    {
        className: "",
        // url: URLs.users.departments,
        isChildMenu: true,
        subUrls: [],
        subMenus: [
            {
                id: 1,
                className: "",
                url: URLs.users.list,
                subUrls: [URLs.users.create, URLs.users.edit],
                subMenus: [URLs.users.create, URLs.users.edit],
                liItemName: "Users",
                routeName: appRoutes.UserManagement, //same as given in roleBasedPermission page
            },
            {
                id: 2,
                className: "",
                url: URLs.users.departments,
                subUrls: [],
                subMenus: [],
                liItemName: "Departments",
                routeName: appRoutes.UserManagement, //same as given in roleBasedPermission page
            },
            {
                id: 3,
                className: "",
                url: URLs.users.entity,
                subUrls: [],
                subMenus: [],
                liItemName: "Entity",
                routeName: appRoutes.UserManagement, //same as given in roleBasedPermission page
            },
            {
                id: 4,
                className: "",
                url: URLs.users.businessUnit,
                subUrls: [],
                subMenus: [],
                liItemName: "Business unit",
                routeName: appRoutes.UserManagement, //same as given in roleBasedPermission page
            },
            {
                id: 5,
                className: "",
                url: URLs.users.employeeLevel,
                subUrls: [],
                subMenus: [],
                liItemName: "Employee level",
                routeName: appRoutes.UserManagement, //same as given in roleBasedPermission page
            },
            {
                id: 6,
                className: "",
                url: URLs.users.customFields,
                subUrls: [],
                subMenus: [],
                liItemName: "Custom fields",
                routeName: appRoutes.UserManagement, //same as given in roleBasedPermission page
            },
        ],
        liItemName: "User management",
        routeName: appRoutes.UserManagement, //same as given in roleBasedPermission page
    },
    {
        className: "",
        url: URLs.policyEditor.flight.list,
        subUrls: [
            URLs.policyEditor.flight.create,
            URLs.policyEditor.flight.edit,
        ],
        liItemName: "Flight policies",
        routeName: appRoutes.flightPolicy, //same as given in roleBasedPermission page
    },
    {
        className: "",
        url: URLs.policyEditor.hotel.list,
        subUrls: [],
        liItemName: "Hotel policies",
        routeName: "hotel-policy",
    },
    {
        className: "",
        url: URLs.ARE.list,
        subUrls: [URLs.ARE.create, URLs.ARE.edit],
        liItemName: "Approval Rules",
        routeName: appRoutes.approvalRules,
    },
    {
        className: "",
        url: URLs.allSettings.default,
        subUrls: [
            URLs.allSettings.booking.default,
            URLs.allSettings.booking.bookForOther,
            URLs.allSettings.booking.hotelBookings,
        ],
        liItemName: "Settings",
        routeName: appRoutes.allSettings,
    },
    {
        className: "",
        url: URLs.paymentMethods.default,
        subUrls: [],
        liItemName: "Payment methods",
        routeName: appRoutes.paymentMethods,
    },
    // Following need to remove in future versions

    {
        className: "",
        url: URLs.integrations.list,
        subUrls: [],
        liItemName: "Integrations",
        routeName: appRoutes.integrations,
    },
    //   {
    //     className: "",
    //     url: URLs.moreSettings.view,
    //     subUrls: [],
    //     liItemName: "More settings",
    //     routeName: appRoutes.moreSettings, //same as given in roleBasedPermission page
    //   },
];
