import createReducer from "../../../../utils/createReducer";
import { toastConstants } from "../../../constants/common/Services/ToastServices.types";

const initialState = {};

export const reducerObj = {
	[toastConstants.SUCCESS]: (prevState, payload) => {
		return {
			type: "toast-success",
			message: payload,
		};
	},
	[toastConstants.ERROR]: (prevState, payload) => {
		return {
			type: "toast-error",
			message: payload,
		};
	},
	[toastConstants.CLEAR]: (prevState, payload) => {
		return {};
	},
};

const ToastServicesReducer = createReducer(initialState, reducerObj);

export default ToastServicesReducer;
