import React from "react";
import { Redirect } from "react-router-dom";
import LocalStorageService from "../../services/LocalStorage";
import { services } from "@itilite/utils";
import axios from "axios";
import { commonUrls } from "../../utils/urls/apiUrls";
import URLs from "../../utils/urls/routeUrls";
import { useDispatch } from "react-redux";
import { saveClientUSM } from "../../redux/actions/common/Auth.action";

function AuthenticationRoute(props) {
    const { IUILocalStorageService } = services;
    const dispatch = useDispatch();
    const noSidebarFromAuth = ["profile", "getting-started", "admin-home"];
    localStorage.clear(); // !clearing old sessions
    if (
        props.computedMatch.params.token &&
        props.computedMatch.params.role &&
        props.computedMatch.params.approute
    ) {
        const refreshToken = props.computedMatch.params.token;
        const role = props.computedMatch.params.role;
        const route = props.computedMatch.params.approute;
        const searchParams = new URL(document.location).searchParams;
        const required = searchParams.get("required"); //

        /** to get the access token with refresh token */
        const data = {
            refresh: refreshToken,
        };

        props.setIslogginIn(true);

        axios
            .post(commonUrls.FETCH_REFRESH_TOKEN, data)
            .then((res) => {
                if (res.status === 200) {
                    const _token = res.data.token;
                    const _showSidebar =
                        noSidebarFromAuth.indexOf(route) === -1;
                    // Local Storage tokens adding
                    LocalStorageService.setAccessToken(_token.access);
                    IUILocalStorageService.setAccessToken(_token.access);
                    IUILocalStorageService.setRefreshToken(_token.refresh);
                    LocalStorageService.setRole(role);
                    localStorage.setItem("redirectedTo", true);

                    let redirectRoute = route;
                    if (route === "profile") {
                        if (required === "dob") {
                            redirectRoute = redirectRoute + "?required=dob";
                        }
                        if (required === "card") {
                            redirectRoute = redirectRoute + "?required=card";
                        }
                    }
                    localStorage.setItem("app", redirectRoute);
                    localStorage.setItem("token", JSON.stringify(_token));

                    props.setInitCheck(true);
                    props.setShowSidebar(_showSidebar);

                    dispatch(saveClientUSM(res.data));
                }
            })
            .catch((error) => {
                console.log(error.response);
                // if (error.response.status === 401) {
                LocalStorageService.removeAllToken();
                props.setInitCheck(true);
                props.setLoginLoader(false);
                // }
            });
        console.log("redirec to login");
        return (
            <Redirect to={{ pathname: URLs.home, state: { loading: true } }} />
        );
    } else {
        LocalStorageService.removeAllToken();
        return (
            <Redirect to={{ pathname: URLs.home, state: { loading: false } }} />
        );
    }
}

export default AuthenticationRoute;
