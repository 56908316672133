import LocalStorageService from "../../services/LocalStorage";

let servicesBaseUrl = process.env.REACT_APP_BASE_URL;
let servicesUSMUrl = process.env.REACT_APP_USM_URL;
let paymentUSMUrl = process.env.REACT_APP_USM_PAYMENT_URL;
let approvalBaseUrl = process.env.REACT_APP_APPROVAL_BASE_URL;

// common urls
export const commonUrls = {
    FETCH_REFRESH_TOKEN: `${servicesUSMUrl}/account/auth/token/refresh/`,
    AUTH_DETAILS: `${servicesBaseUrl}/api/v1/staff/details`,
    HEADER: `${servicesBaseUrl}/api/v1/nav_bar/header/`,
    FOOTER: `${servicesBaseUrl}/api/v1/nav_bar/footer/`,
    AIRPORTS: `${approvalBaseUrl}/v2/approvals/airports`,
    COUNTRY_AC: `${servicesBaseUrl}/api/v1/autocomplete/country`,
    AIRLINE_AC: `${servicesBaseUrl}/api/v1/autocomplete/airline`,
    CITY_AC: `${servicesBaseUrl}/api/v1/autocomplete/city`,
    AIRPORT_AC: `${servicesBaseUrl}/api/v1/autocomplete/airport`,
    GEOLOCATION: "https://ipapi.co/json/",
    COUNTRY_CODE_LIST: `${servicesUSMUrl}/api/v1/user-management/country-code-details/`,
    clientSpecificUrls: (clientId) => {
        return {
            GRADE: `${servicesBaseUrl}/api/v1/client/${clientId}/level`,
        };
    },
    MENU_LIST: `${servicesBaseUrl}/api/v1/nav_bar/config`,
    AUTH_API: `${servicesUSMUrl}/api/v1/user-management/user/authorization`
};

// Approval urls
export const approvalUrls = {
    generateUrlWithClientId: (clientId, ruleId) => {
        return {
            GET_ALL_RULES: `${approvalBaseUrl}/v2/approvals/client/${clientId}/rules`,
            GET_DEPARTMENT: `${approvalBaseUrl}/v2/approvals/client/${clientId}/dept`,
            GET_GRADE: `${approvalBaseUrl}/v2/approvals/client/${clientId}/level`,
            GET_APPROVAL_LABELS: ruleId
                ? `${approvalBaseUrl}/v2/approvals/get_usm_approvers_label/${clientId}?rule_id=${ruleId}`
                : `${approvalBaseUrl}/v2/approvals/get_usm_approvers_label/${clientId}`,
            GET_RULE_BY_ID: `${approvalBaseUrl}/v2/approvals/client/${clientId}/rules`,
            UPDATE_RULE_ID: `${approvalBaseUrl}/v2/approvals/client/${clientId}/rules`,
            DELETE_RULE_ID: `${approvalBaseUrl}/v2/approvals/client/${clientId}/rules`,
            CREATE_RULE: `${approvalBaseUrl}/v2/approvals/client/${clientId}/rules`,
            INPUT_VARIABLES: `${approvalBaseUrl}/v2/approvals/client/${clientId}/input_variables`,
            CHANGE_PRIORITY: `${approvalBaseUrl}/v2/approvals/client/${clientId}/rules/priority`,
            REORDER_RULE: `${approvalBaseUrl}/v2/approvals/client/${clientId}/rules/reorder`,
            GET_CLIENT_PROFILE: `${servicesBaseUrl}/api/v1/client/${clientId}/`,
        };
    },
    UPDATING_SESSION_VARIABLE: `${servicesUSMUrl}/account/auth/token/verify/`,
};

// Onboarding urls
export const onboardingUrls = {
    FORM_SUBMIT: `${servicesUSMUrl}/api/v1/onboarding/client-sign-up/`,
    RESEND_EMAIL: `${servicesUSMUrl}/api/v1/onboarding/client-resend-verify-email/`,
    COMPANY_AUTH: `${servicesUSMUrl}/api/v1/onboarding/validate-req`,
    COMPANY_CREATE: `${servicesUSMUrl}/api/v1/onboarding/new-company`,
};

// Mastermind Urls
const M_BASE_URL = `${servicesBaseUrl}/api/v1/mastermind`;
export const mastermindUrls = {
    GET_SUGGESTION: `${M_BASE_URL}/module_view/`,
    GET_RECOMMENDATION: `${M_BASE_URL}/client/recommendations/`,
    GET_CHART_DATA: `${M_BASE_URL}/client/chart_data_above_policy/`,
    SEND_MAIL: `${M_BASE_URL}/client/send_mail/`,
};

//Policy Editor URLs
export const PolicyUrls = {
    generateUrlFlight: () => {
        return {
            RULE: `${servicesBaseUrl}/api/v1/policy/flight/`,
        };
    },
    generateUrlHotel: () => {
        return {
            RULE: `${servicesBaseUrl}/api/v1/policy/hotel/`,
            EMPLOYEE_LEVELS: `${servicesBaseUrl}/api/v1/employee_level/`,
            DEFAULT_EDIT: `${servicesBaseUrl}/api/v1/policy/hotel/`,
            SEARCHRULE: `${servicesBaseUrl}/api/v1/search/policy/hotel/`,
            CHANGERULEACTION: `${servicesBaseUrl}/api/v1/policy/hotel/`,
        };
    },
    getLocationForDropdownUrl: (search, page) => {
        return {
            LOCATIONS: `${servicesBaseUrl}/api/v1/geo_location/${search}?page=${page}`,
        };
    },
    getLocationForDropdownUrl: (page) => {
        return {
            LOCATIONS: `${servicesBaseUrl}/api/v1/geo_location/e?page=${page}`,
        };
    },
    generateHotelList: () => {
        return {
            HOTEL_LIST: `${servicesBaseUrl}/api/v1/hotels`,
        };
    },
    modifyPreferredHotelList: () => {
        return {
            MODIFY_HOTEL_LIST: `${servicesBaseUrl}/api/v1/preferred_hotels/`,
        };
    },
    modifyBlockedHotelList: () => {
        return {
            MODIFY_HOTEL_LIST: `${servicesBaseUrl}/api/v1/blacklisted_hotels/`,
        };
    },
};
// GettingStartedWith urls
export const gettingStartedWith = {
    GET_GETTING_STARTED_WITH: `${servicesUSMUrl}/api/v1/onboarding/getting-started/`,
};
// Integrations urls
export const integrations = {
    generateUrlWithClientId: (clientId) => {
        return {
            GET_USER_INTEGRATIONS: `${servicesBaseUrl}/api/v1/integrations/client-integration-list/${clientId}`,
            GET_OTHER_INTEGRATIONS: `${servicesBaseUrl}/api/v1/integrations/integration-list/${clientId}`,
        };
    },
    REQUEST_INTEGRATIONS: `${servicesBaseUrl}/api/v1/integrations/integration-request/`,
};
// MoreSettings urls
export const moreSettings = {
    generateUrlWithClientId: (clientId) => {
        return {
            MORE_SETTINGS_REQUEST: `${servicesBaseUrl}/api/v1/client/${clientId}/more_settings/`,
        };
    },
};

// All settings url
export const allSettings = {
    generateUrlWithClientId: (clientId, path) => {
        return {
            // https://stageservice.iltech.in/api/v1/client/1884/user_bfo_list
            GET_USER_LIST_BFO: `${servicesBaseUrl}/api/v1/client/${clientId}/user_bfo_list`,
            GET_SELECTED_USER_LIST_BFO: `${servicesBaseUrl}/api/v1/client/${clientId}/user_bfo_list?action=view`,
            GET_SEARCH_SELECTED_USER_LIST: `${servicesBaseUrl}/api/v1/client/${clientId}/user_bfo_list?action=view&search=${path}`,
            GET_USER_LIST_BFO_PAGE: `${servicesBaseUrl}/api/v1/client/${clientId}/user_bfo_list?length=10&page=${path}`,
            GET_USER_LIST_BFO_NEXT: `${servicesBaseUrl}${clientId}`,
            GET_SEARCH_USER_LIST: `${servicesBaseUrl}/api/v1/client/${clientId}/user_bfo_list?search=${path}`,
            GET_PUT_BOOKING_SETTINGS: `${servicesBaseUrl}/api/v1/client/${clientId}/config/booking/`,
            GET_BOOKING_FOR_OTHER_SETTINGS: `${servicesBaseUrl}/api/v1/client/${clientId}/bfo_settings/`,
            GET_HOTEL_PAYMENT_TYPE_CONFIG: `${servicesBaseUrl}/api/v1/client/${clientId}/config/booking/hotel-bookings`,
            PUT_HOTEL_PAYMENT_TYPE_CONFIG: `${servicesBaseUrl}/api/v1/client/${clientId}/config/booking/hotel-bookings`,
            POST_MEMBERSHIP_EMAIL_TRIGGER: `${servicesBaseUrl}/api/v1/client/${clientId}/config/booking/membership-email-trigger`,
            PUT_BOOKING_FOR_OTHER_SETTINGS: `${servicesBaseUrl}/api/v1/client/${clientId}/bfo_settings/?action=${path}`,
            PUT_BOOKING_BFO_SETTINGS: `${servicesBaseUrl}/api/v1/client/${clientId}/bfo_settings/?action=enable_bfo`,
            PUT_BOOKING_BANNER: `${servicesBaseUrl}/api/v1/client/${clientId}/config/banner/`,
            GET_COMPANY_DETAILS: `${servicesBaseUrl}/api/v1/client/${clientId}/general-settings`,
            GET_RESHOP_DETAILS: `${servicesBaseUrl}/api/v1/client/${clientId}/reshop-settings`,
            PUT_RESHOP_DETAILS: `${servicesBaseUrl}/api/v1/client/${clientId}/reshop-settings`,
        };
    },
    generateUrlForCustomDimension: (clientId, customId, optionId) => {
        return {
            GET_CUSTOM_DIMENSION: `${servicesBaseUrl}/api/v1/client/${clientId}/config/custom-dimensions`,
            GETONE_CUSTOM_DIMENSION: `${servicesBaseUrl}/api/v1/client/${clientId}/config/custom-dimensions?id=${customId}`,
            SELECTED_OPTIONS_URL: `${servicesBaseUrl}/api/v1/client/${clientId}/config/custom-dimensions/${customId}/options`,
            DELETE_SELECTED_OPTIONS_URL: `${servicesBaseUrl}/api/v1/client/${clientId}/config/custom-dimensions/${customId}/options?id=${optionId}`,
            GET_CUSTOM_DIMENSION_CONFIG: `${servicesBaseUrl}/api/v1/client/${clientId}/config/custom-dimensions/maximum-config`,
        };
    },
};

export const travelApprovals = {
    generateUrlWithClientId: (clientId) => {
        return {
            TRAVEL_APPROVAL_CONFIGS: `${servicesBaseUrl}/api/v1/client/${clientId}/config/approval-reason/`,
        };
    },
};

export const notifications = {
    generateUrlWithCompanyId: (companyId) => {
        return {
            NOTIFICATION_ADMIN_LIST: `${servicesUSMUrl}/api/v1/user-management/company/${companyId}/admin-users`,
            NOTIFICATION_FETCH_CONFIGS: `${servicesUSMUrl}/api/v1/user-management/company/${companyId}/signup-config`,
        };
    },
};
// payment card
export const paymentCard = {
    GET_CARD_DETAILS: `${paymentUSMUrl}/api/v1/card/list`,
    MAKE_CARD_DEFAULT: `${paymentUSMUrl}/api/v1/card/default`,
    STAFF_DETAILS: `${servicesBaseUrl}/api/v1/staff-payment-details`,
    ADD_CARD_REDIRECT: `${paymentUSMUrl}/api/v1/auth`,
    DEL_CARD_DETAILS: `${paymentUSMUrl}/api/v1/card/delete`,
};
export const LandingUrls = {
    INFO: `${servicesBaseUrl}/api/v1/client/landing_page/apiview/`,
};

export const allTrips = {
    generateUrlWithParams: (urlParams = "", clientId) => {
        const url =
            "/api/v1/service/all-trips-list/" +
            clientId +
            (urlParams ? "?" + urlParams : "");
        return {
            GET_ALL_TRIPS_LIST: `${servicesBaseUrl}${url}`,
        };
    },
    GET_TRIP_DETAILS: `${servicesBaseUrl}/api/v1/trip/view-trip-details/`,
};
