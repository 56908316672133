import {
    INPUT_VALUE,
    SET_REWARDS,
    SET_PAYMENT_METHOD,
    SET_COUNTRY,
    SET_CURRENCY,
    UPDATE_ERRORS,
    UPDATE_ERROR_MSG,
    SET_PRODUCT_TYPE,
    SET_REQUEST_TOKEN,
    SUBMIT_FORM,
    SET_AUTHENTICATION,
} from "../../../constants/IL_Admin/Onboarding/CompanyDetails.types";

const initialState = {
    isLoading: true,
    isSubmitted: false,
    isAuthenticated: 0,
    authMessage: "",
    companyDetails: {
        company_name: "",
        payment_method: "",
        is_central_card: "",
        plan_id: "",
        reward_points: 30,
        payment_gateway: "TestInstaMojo",
        currency: "",
        country_name: "",
        country_code: "",
    },
    productType: {
        is_travel: 0,
        is_expense: 0,
    },
    errors: [],
    errorMsgs: {},
    reqToken: "",
    geolocation: {},
};
export default function reducerObj(state = initialState, action = {}) {
    let companyDetails_GEO = { ...state.companyDetails };
    let _geoLocationCurrency = { ...state.geolocation };
    const { type, payload } = action;
    switch (type) {
        case INPUT_VALUE:
            return {
                ...state,
                companyDetails: payload.companyDetails,
            };

        case SET_PAYMENT_METHOD:
            let cd__Payment = { ...state.companyDetails };
            if (payload.paymentMethod === 2) {
                cd__Payment.payment_method = 1;
                cd__Payment.is_central_card = 1;
            } else {
                cd__Payment.payment_method = payload.paymentMethod;
                cd__Payment.is_central_card = 0;
            }
            return {
                ...state,
                companyDetails: cd__Payment,
            };

        case SET_REWARDS:
            let cd__Rewards = { ...state.companyDetails };
            cd__Rewards.plan_id = payload.rewards;
            return {
                ...state,
                companyDetails: cd__Rewards,
            };

        case UPDATE_ERRORS:
            return {
                ...state,
                errors: payload.errors,
            };

        case UPDATE_ERROR_MSG:
            return {
                ...state,
                errorMsgs: payload.errorMsg,
            };

        case SET_REQUEST_TOKEN:
            return {
                ...state,
                reqToken: payload.token,
            };
        case SET_AUTHENTICATION:
            return {
                ...state,
                isAuthenticated: payload.isAuthenticated,
                authMessage: payload.authMessage,
            };
        case SET_PRODUCT_TYPE:
            return {
                ...state,
                productType: payload.result,
            };
        case SUBMIT_FORM:
            return {
                ...state,
                isSubmitted: payload.isSubmitted,
            };
        case SET_COUNTRY:
            companyDetails_GEO["country_name"] = payload.country_name;
            companyDetails_GEO["country_code"] = payload.country_code;
            return {
                ...state,
                companyDetails: companyDetails_GEO,
                geolocation:
                    payload.geolocation === undefined
                        ? _geoLocationCurrency
                        : payload.geolocation,
                isLoading: false,
            };
        case SET_CURRENCY:
            companyDetails_GEO["currency"] = payload.currency;
            return {
                ...state,
                companyDetails: companyDetails_GEO,
                geolocation:
                    payload.geolocation === undefined
                        ? _geoLocationCurrency
                        : payload.geolocation,
                // isLoading: false,
            };

        default:
            return state;
    }
}
