import React , {lazy} from "react";
import LocalStorageService from "../services/LocalStorage";
import { roleBasedPermission } from "./roleBasedPermission";
import SuspenseContainer from "./suspenseContainer";

const Page404 = lazy(() => import("../components/Page404/Page404") );

const AllowedApp = (props) => {
	const role = LocalStorageService.getRole();
    const selectedData = roleBasedPermission[props.route];
	const isAllowed =  selectedData && selectedData.indexOf(role) !== -1;
    const returnData = isAllowed ? props.children : <Page404 />;
    
	return <SuspenseContainer> {returnData} </SuspenseContainer>
}

export default AllowedApp;