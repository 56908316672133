import {
    SHOW_BOOKING_SKELETEN,
    HIDE_BOOKING_SKELETEN,
    SET_BOOKING_DETAILS,
    SET_BANNER_MESSAGE,
    BOOKING_ENABLE,
    BOOKING_DISABLE,
    FLIGHT_ENABLE,
    FLIGHT_DISABLE,
    HOTEL_ENABLE,
    HOTEL_DISABLE,
    CAR_ENABLE,
    CAR_DISABLE,
    BUS_ENABLE,
    BUS_DISABLE,
    TRAIN_ENABLE,
    TRAIN_DISABLE,
} from "../../../../constants/IL_Admin/AllSettings";

const initialState = {
    showSkeleten: true,
    booking: false,
    flight: false,
    hotel: false,
    book_for_other: true,
    book_for_other_staff: true,
    banner_messages: null,
};

const BookingSettings = function reducerObj(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case BOOKING_ENABLE:
        case BOOKING_DISABLE:
            return {
                ...state,
                booking: payload,
            };
        case FLIGHT_ENABLE:
        case FLIGHT_DISABLE:
            return {
                ...state,
                flight: payload,
            };
        case HOTEL_ENABLE:
        case HOTEL_DISABLE:
            return {
                ...state,
                hotel: payload,
            };
        case CAR_ENABLE:
        case CAR_DISABLE:
            return {
                ...state,
                car: payload,
            };
        case BUS_ENABLE:
        case BUS_DISABLE:
            return {
                ...state,
                bus: payload,
            };
        case TRAIN_ENABLE:
        case TRAIN_DISABLE:
            return {
                ...state,
                train: payload,
            };
        case SET_BOOKING_DETAILS:
            return {
                ...state,
                ...payload,
            };
        case SET_BANNER_MESSAGE:
            return {
                ...state,
                banner_messages: payload,
            };
        case SHOW_BOOKING_SKELETEN:
            return {
                ...state,
                showSkeleten: true,
            };
        case HIDE_BOOKING_SKELETEN:
            return {
                ...state,
                showSkeleten: false,
            };
        default:
            return state;
    }
};

export default BookingSettings;
