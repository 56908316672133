import { services } from "@itilite/utils";
import LocalStorageService from "../services/LocalStorage";

function userLogout(_redirectUrl) {
    const { IUILogoutService } = services;
    IUILogoutService(_redirectUrl, callback);
}
function callback() {
    LocalStorageService.removeAllToken();
}

function sortArrayOfObj(arr, key) {
    const sortedArr = arr.sort((a, b) =>
        a[key].localeCompare(b[key], {
            sensitivity: "base",
        })
    );
    return sortedArr;
}

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
};

export default scrollToTop;

export { userLogout, sortArrayOfObj, scrollToTop };
