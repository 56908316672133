import React from "react";
import "./Navbar.scss";

import { makeStyles } from "@material-ui/core/styles";
import { Container, AppBar, Toolbar, Box } from "@material-ui/core";
import __globals from "../../theming/__globals";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    height100: {
        height: "100%",
    },
    padding0: {
        padding: 0,
    },
    itilite__navbar: {
        boxShadow: `0px 0px 16px -7px ${__globals.palette.secondary.light}`,
    },
    navbar: {
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: 0,
    },
}));

export default function HeaderNonLogged() {
    const classes = useStyles();
    // const { t } = useTranslation();
    return (
        <>
            <AppBar position="fixed" style={{ height: "64px" }}>
                <Toolbar
                    className={clsx(
                        classes.itilite__navbar,
                        classes.height100,
                        "p-0"
                    )}
                >
                    <Container maxWidth="xl" className={classes.height100}>
                        <Box
                            display="flex"
                            width="100%"
                            className={classes.height100}
                        >
                            <Box
                                flexGrow={1}
                                display="flex"
                                alignItems="center"
                            >
                                <img
                                    src={`${process.env.REACT_APP_IL_CDN_URL}/images/Logo.svg`}
                                    // src={headerLinks.left[0].path}
                                    alt={"Itilite"}
                                />
                            </Box>
                        </Box>
                    </Container>
                </Toolbar>
            </AppBar>
        </>
    );
}
