import { sortArrayOfObj } from "../../../../../utils/_helperFunctions";
import {
    GET_BOOK_FOR_OTHER,
    POST_BOOK_FOR_OTHER,
    GET_USER_LIST,
    GET_USER_LIST_PAGENATION,
    ADD_USER_IN_LIST,
    REMOVE_USER_IN_LIST,
    SEARCH_IN_SELECTEDUSER,
    GET_SELECTED_USER_LIST,
    GET_SELECTEDUSER_LIST_PAGENATION,
} from "../../../../constants/IL_Admin/AllSettings";

const initialState = {
    usersList: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    bfoData: {
        enable_bfo: false,
        custom_bfo: "all_employees",
        user_emails: [],
        enable_bfo_staff: "bfo_guest_and_staff",
        split_trips: "unique_policy",
        booked_for_me_tab: true,
        bfo_profile_info: true,
        send_tickets_to_all: "tickets_to_all",
    },
    selectedUsersList: {
        count: 0,
        next: null,
        previous: null,
        results: [],
    },
    selectedUser: [],
};

const BookForOthersReducer = function reducerObj(
    state = initialState,
    action = {}
) {
    const { type, payload } = action;
    switch (type) {
        case GET_USER_LIST:
            const nonSelectedUserList = payload.results.filter(function (item) {
                return !state.selectedUser.find(function (user) {
                    return user.username === item.email;
                });
            });

            console.log(nonSelectedUserList);

            return {
                ...state,
                usersList: {
                    ...state.usersList,
                    count: payload.count,
                    next: payload.next,
                    previous: payload.previous,
                    results: nonSelectedUserList,
                },
            };

        case GET_SELECTED_USER_LIST:
            // const SelectedUserList = payload.results.filter((item) => {
            //     return !state.selectedUser.results.find(function (user) {
            //         return user.username === item.email;
            //     });
            // });

            // console.log("GET_SELECTED_USER_LIST", SelectedUserList);

            return {
                ...state,
                selectedUsersList: {
                    ...state.selectedUsersList,
                    count:
                        state.bfoData.custom_bfo === "all_employees"
                            ? 0
                            : payload.count,
                    next: payload.next,
                    previous: payload.previous,
                    results:
                        state.bfoData.custom_bfo === "all_employees"
                            ? []
                            : payload.results,
                },
            };

        case GET_USER_LIST_PAGENATION:
            const nonSelectedUserListPG = payload.results.filter(function (
                item
            ) {
                return !state.selectedUser.find(function (user) {
                    return user.username === item.email;
                });
            });

            console.log("nonSelectedUserListPG", nonSelectedUserListPG);
            return {
                ...state,
                usersList: {
                    ...state.usersList,
                    count: payload.count,
                    next: payload.next,
                    previous: payload.previous,
                    results: [
                        ...state.usersList.results,
                        ...nonSelectedUserListPG,
                    ],
                },
            };

        case GET_SELECTEDUSER_LIST_PAGENATION:
            // const nonSelectedUserListPG = payload.results.filter(function (
            //     item
            // ) {
            //     return !state.selectedUser.find(function (user) {
            //         return user.username === item.email;
            //     });
            // });

            // console.log("nonSelectedUserListPG", nonSelectedUserListPG);
            return {
                ...state,
                selectedUsersList: {
                    ...state.selectedUsersList,
                    count: payload.count,
                    next: payload.next,
                    previous: payload.previous,
                    results: [
                        ...state.selectedUsersList.results,
                        ...payload.results,
                    ],
                },
            };
        case GET_BOOK_FOR_OTHER:
            const userlist = sortArrayOfObj(payload.user_emails, "first_name");

            return {
                ...state,
                bfoData: {
                    enable_bfo: payload.enable_bfo,
                    custom_bfo: payload.custom_bfo,
                    user_emails: payload.user_emails,
                    enable_bfo_staff: payload.enable_bfo_staff,
                    split_trips: payload.split_trips,
                    booked_for_me_tab: payload.booked_for_me_tab,
                    bfo_profile_info: payload.bfo_profile_info,
                    send_tickets_to_all: payload.send_tickets_to_all,
                },
                selectedUser: userlist,
            };

        case POST_BOOK_FOR_OTHER:
            return {
                ...state,
                bfoData: { ...state.bfoData, ...payload },
            };

        case ADD_USER_IN_LIST:
            const newList = state.usersList.results.filter(
                (item) => item.email !== payload.username
            );

            const args = [...state.selectedUser, payload];
            const sortSelectedUser = sortArrayOfObj(args, "first_name");

            return {
                ...state,
                usersList: { ...state.usersList, results: newList },
                // bfoData: {
                //   ...state.bfoData,
                //   user_emails: [...state.bfoData.user_emails, payload.username],
                // },
                selectedUser: sortSelectedUser,
            };

        case REMOVE_USER_IN_LIST:
            const removedUsers = state.selectedUser.filter(
                (item) => item.username !== payload.data.username
            );

            const userobj = {
                id: payload.data.id,
                first_name: payload.data.first_name,
                last_name: payload.data.last_name || payload.data.name,
                email: payload.data.username,
            };
            const usersList = [userobj, ...state.usersList.results];
            const sortRemovedList = sortArrayOfObj(usersList, "first_name");

            return {
                ...state,
                usersList: {
                    ...state.usersList,
                    results: sortRemovedList,
                },
                bfoData: {
                    ...state.bfoData,
                    user_emails: payload.fitleredUsers,
                },
                selectedUser: removedUsers,
            };

        case SEARCH_IN_SELECTEDUSER:
            return {
                ...state,
                selectedUsersList: {
                    ...state.selectedUsersList,
                    count: payload.count,
                    next: payload.next,
                    previous: payload.previous,
                    results: payload.results,
                },
            };

        default:
            return state;
    }
};
export default BookForOthersReducer;
