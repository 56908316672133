import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from "i18next-browser-languagedetector";
import langData from './langData.json';

var resources = {};
// console.log('langData>', langData)
langData.forEach(function (o) {
	Object.keys(o).forEach(function (key) {
		var langCode, langKey, langKeyVal;
		if (key !== "key") {
			langCode = key;
			langKey = o.key;
			langKeyVal = o[key];
			if (resources[langCode] === undefined) {
				resources[langCode] = {
					translation: {},
				};
			}
			resources[langCode].translation[langKey] = langKeyVal;
		}
	});
});

// console.log('i18n', localStorage.getItem('language'))
i18n
	.use(detector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: `${localStorage.getItem('language')}`,
		// lng: "en",
		fallbackLng: "en",
		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;