// Contains all URLs required for the app
const URLs = {
    // Routing urls
    home: "/",
    mastermind: "/mastermind",
    detailedSuggestion: "/mastermind/detailed-suggestion",
    ARE: {
        list: "/approval-rules",
        create: "/create-approval-flow",
        edit: "/edit-approval-flow",
    },
    trips: {
        allTrips: "/all-trips",
        approvals: "/approvals",
    },
    policyEditor: {
        flight: {
            create: "/policy/flight/create",
            edit: "/policy/flight/edit",
            list: "/policy/flight",
        },
        hotel: {
            create: "/policy/hotel/create",
            edit: "/policy/hotel/edit",
            list: "/policy/hotel",
            summary: "/policy/hotel/summary",
            preferences: "/policy/hotel/preferences",
        },
    },
    onboarding: {
        profile: "/onboarding",
        company: "/onboarding-company-details",
    },
    gettingStarted: "/getting-started",
    users: {
        create: "/user/create",
        edit: "/user/edit",
        list: "/user/list",
        profile: "/profile",
        customFields: "/custom-fields",
        bulkactionHistory: "/user/bulkactionhistory",
        bulkImport: "/user/import",
        departments: "/departments",
        entity: "/entities",
        businessUnit: "/business-unit",
        employeeLevel: "/employee-level",
    },
    integrations: {
        list: "/integrations",
    },
    moreSettings: {
        view: "/more-settings",
    },
    adminActions: {
        signupRequestList: "/admin-actions/sign-up-requests",
    },
    allSettings: {
        default: "/settings",
        booking: {
            default: "/settings/bookings",
            bookForOther: "/settings/bookings/book-for-other",
            hotelBookings: "/settings/bookings/hotel-bookings",
        },
        custom: {
            default: "/settings/custom",
            addCustomFields: "/settings/add-custom-field",
            edit: "/settings/custom/edit/:id",
        },
        travelApprovals: {
            default: "/settings/travel-approvals",
        },
        notifications: {
            default: "/settings/notifications",
        },
        companyDetailsSetting: { default: "/settings/company-details" },
        reShopsSetting: { default: "/settings/re-shop" },
    },
    paymentMethods: {
        default: "/payment-methods",
    },
    landing: {
        default: "/admin-home"
    }
    // API urls
};

export default URLs;
