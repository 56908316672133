/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState, lazy } from "react";
import {Route, Switch, Redirect, BrowserRouter as Router } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import AuthenticationRoute from "./Authentication/AuthenticationRoute";
import AuthenticationRouteILAdmin from "./Authentication/AuthenticationRouteILAdmin";
import PublicRoute from "./Authentication/PublicRoute";
import PrivateRoute from "./Authentication/PrivateRoute";
import Header from "../components/Navbar/Header";
import HeaderNonLogged from "../components/Navbar/HeaderNonLogged";
import Footer from "../components/Footer/Footer";
import ToastService from "../services/ToastService";
import LocalStorageService from "../services/LocalStorage";
import {
    fetchAuthDetails,
    fetchClientDetails,
    fetchClietSidebarMenus,
    saveClientUSM,
} from "../redux/actions/common/Auth.action";
import { SET_AUTH_DETAILS } from "../redux/constants/common/Auth.types";
import { routeUtils } from "../utils/routesUtils";

import GettingStarted from "../apps/GettingStartedWith";
import { getGettingStartedWith } from "../redux/actions/GettingStartedWith/GettingStartedWith.action";

import { commonUrls } from "../utils/urls/apiUrls";
import URLs from "../utils/urls/routeUrls";
import { validateAccessToken } from "../utils/validateAccessToken";
import AllowedApp from "../utils/allowedAppsForRoles";
import SuspenseContainer from "../utils/suspenseContainer";
import AppWrapper from "../utils/appWrapper";
import { appRoutes } from "../utils/appRoutes";
import GTMService from "../services/GTMService";
import Page404 from "../components/Page404/Page404";

// lazy loading components
const Login = lazy(() => import("../components/Login/Login"));
const ApprovalRuleList = lazy(() =>
    import("../apps/ApprovalRule/RuleList/ApprovalRuleList")
);
const NewApprovalRule = lazy(() =>
    import("../apps/ApprovalRule/NewApprovalRule")
);
const Mastermind = lazy(() => import("../apps/Mastermind"));
const SuggestionDetailsPage = lazy(() =>
    import("../apps/Mastermind/pages/SuggestionDetailsPage")
);
const OnboardingProfileDetails = lazy(() =>
    import("../apps/Onboarding/ProfileDetails")
);
const OnboardingCompanyDetails = lazy(() =>
    import("../apps/Onboarding/CompanyDetails")
);
const HotelPolicyList = lazy(() =>
    import("../apps/PolicyEditor/pages/HotelPolicy/List")
);
const CompanyDetailsSetting = lazy(() =>
    import("../apps/AllSettings/CompanyDetailsSettings")
);
const ReShopsSetting = lazy(() => import("../apps/AllSettings/Reshop"));
const HotelPolicySingle = lazy(() =>
    import("../apps/PolicyEditor/pages/HotelPolicy/Single")
);
const HotelPolicySummary = lazy(() =>
    import("../apps/PolicyEditor/pages/HotelPolicy/Summary")
);
const PreferredBlockedHotels = lazy(() =>
    import("../apps/PolicyEditor/pages/HotelPolicy/PreferredBlockedHotels")
);
const FlightPolicyList = lazy(() =>
    import("../apps/PolicyEditor/pages/FlightPolicy/List")
);
const FlightPolicySingle = lazy(() =>
    import("../apps/PolicyEditor/pages/FlightPolicy/Single")
);
const UserCreation = lazy(() =>
    import("../apps/UserManagement/UserCreateEdit")
);
const UsersList = lazy(() => import("../apps/UserManagement/UserList"));
const CustomFields = lazy(() => import("../apps/UserManagement/CustomFields"));
const Departments = lazy(() => import("../apps/UserManagement/Departments"));
const Entity = lazy(() => import("../apps/UserManagement/Entity"));
const BusinessUnit = lazy(() => import("../apps/UserManagement/BusinessUnit"));
const UserBulkUpload = lazy(() =>
    import("../apps/UserManagement/UserBulkUpload")
);
const UserBulkActionHistory = lazy(() =>
    import("../apps/UserManagement/UserBulkActionHistory")
);
const EmployeeLevel = lazy(() =>
    import("../apps/UserManagement/EmployeeLevel")
);
const Integrations = lazy(() => import("../apps/Integrations/Integrations"));
const MoreSettingsView = lazy(() =>
    import("../apps/MoreSettings/MoreSettings")
);
const Settings = lazy(() => import("../apps/AllSettings/index"));
const BookingSetting = lazy(() => import("../apps/AllSettings/Bookings"));
const BookingForOther = lazy(() =>
    import("../apps/AllSettings/Bookings/BookingForOther")
);
const HotelBookings = lazy(() =>
    import("../apps/AllSettings/Bookings/HotelBookings")
);
const PaymentMethods = lazy(() => import("../apps/PaymentMethods/index"));
const SignUpRequest = lazy(() => import("../apps/AdminActions/SignUpRequest"));
const TravelApprovals = lazy(() =>
    import("../apps/AllSettings/TravelApprovals")
);
const CustomFieldsSettings = lazy(() =>
    import("../apps/AllSettings/CustomFieldsSettings")
);
const AddCustomFieldsSettings = lazy(() =>
    import("../apps/AllSettings/CustomFieldsSettings/AddCustomField")
);
const EditCustomFieldsSettings = lazy(() =>
    import("../apps/AllSettings/CustomFieldsSettings/EditCustomField")
);
const LandingPage = lazy(() => import("../apps/LandingPage"));
const AllTrips = lazy(() => import("../apps/Trips/pages/AllTrips"));

const Notifications = lazy(() => import("../apps/AllSettings/Notifications"));

function Routes() {
    const dispatch = useDispatch();
    const [isInitCheck, setInitCheck] = useState(false);
    const [loginLoader, setLoginLoader] = useState(null);
    const [showSidebar, setShowSidebar] = useState(false);
    const [isLoggingIn, setIslogginIn] = useState(false);

    // accessing getting-started reducer state.
    const { result, isLoading } = useSelector(
        (state) => state.GettingStartedWithReducer
    );
    const { authDetails, clientProfile, clientUSM } = useSelector(
        (state) => state.AuthReducer
    );
    const { client: userClientID } = authDetails;
    useEffect(() => {
        (async () => {
            if (
                localStorage.getItem("token") &&
                localStorage.getItem("il_travel_access_token")
            ) {
                let accessToken = await validateAccessToken().then((r) => r);
                if (!isInitCheck) {
                    await axios({
                        method: "GET",
                        url: commonUrls.AUTH_API,
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    })
                        .then((res) => {
                            if (res.status === 200) {
                                if (
                                    localStorage.getItem("app") !== "profile" ||
                                    localStorage.getItem("app") !==
                                        "getting-started" ||
                                    localStorage.getItem("app") !==
                                        "admin-home" ||
                                    localStorage.getItem("app") !== "all-trips"
                                ) {
                                    setShowSidebar(true);
                                }
                                if (
                                    window.location.pathname ===
                                        "/getting-started" ||
                                    window.location.pathname === "/approvals" ||
                                    window.location.pathname === "/all-trips"
                                ) {
                                    setShowSidebar(false);
                                }
                                dispatch(saveClientUSM(res.data));
                                setInitCheck(true);
                                let app_route = routeUtils(
                                    localStorage.getItem("app")
                                );
                                return <Redirect to={app_route} />;
                            }
                        })
                        .catch((err) => {
                            if (err.response && err.response.status === 401) {
                                LocalStorageService.removeAccessToken();
                                localStorage.removeItem("token");
                                setInitCheck(true);
                                setLoginLoader(false);
                                return (
                                    <Redirect to={{ pathname: URLs.home }} />
                                );
                            }
                        });
                }
                if (localStorage.getItem("client_id")) {
                    // alert('Il Admin client')
                    (async () => {
                        const response = await axios({
                            method: "GET",
                            url: commonUrls.AUTH_DETAILS,
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        });
                        if (response.status === 200) {
                            let authDetailsWithDifferentClientID = {
                                ...response.data,
                            };
                            delete authDetailsWithDifferentClientID.client;

                            authDetailsWithDifferentClientID.client =
                                localStorage.getItem("client_id");
                            authDetailsWithDifferentClientID.role =
                                LocalStorageService.getRole();
                            dispatch({
                                type: SET_AUTH_DETAILS,
                                payload: authDetailsWithDifferentClientID,
                            });
                            localStorage.setItem(
                                "language",
                                response.data.language
                            );
                        } else {
                            ToastService.notify("Something went wrong");
                        }
                    })();
                } else {
                    console.log("Auth details fetch");
                    await dispatch(fetchAuthDetails());
                }
            }
            if (isInitCheck) setLoginLoader(true);
        })();
    }, [isInitCheck, dispatch]);

    useEffect(() => {
        if (localStorage.getItem("client_id")) {
            let clientId = localStorage.getItem("client_id");
            dispatch(fetchClientDetails(clientId));
            dispatch(fetchClietSidebarMenus(clientId));
        }
    }, [dispatch, isInitCheck]);

    useEffect(() => {
        if (window.location.pathname === "/getting-started") {
            dispatch(getGettingStartedWith());
        }
    }, [window.location.pathname]);

    useEffect(() => {
        if (userClientID) {
            dispatch(fetchClietSidebarMenus(userClientID));
            if (!localStorage.getItem("client_id")) {
                dispatch(fetchClientDetails(userClientID));
            }
        }
    }, [dispatch, userClientID]);

    useEffect(() => {
        if (clientProfile?.id && authDetails?.id) {
            GTMService.init({
                firstName: authDetails.first_name,
                lastName: authDetails.name,
                userName: authDetails.username,
                email: authDetails.email,
                clientId: authDetails.client,
                role: authDetails.role,
                userId: clientUSM?.user_ids?.usm_user_id,
                companyName: clientUSM?.company_name,
                usm_company_id: clientUSM?.company_ids?.usm_company_id
            });
        }
    }, [clientProfile, authDetails]);

    return (
        <Fragment>
            <Router getUserConfirmation={() => {}}>
                {localStorage.getItem("token") ? (
                    <Header />
                ) : (
                    <HeaderNonLogged />
                )}
                <AppWrapper isSidebar={showSidebar} isInitCheck={isInitCheck}>
                    <Switch>
                        <AuthenticationRoute
                            exact
                            path="/auth/:token/:role/:approute"
                            isInitCheck={isInitCheck}
                            setInitCheck={setInitCheck}
                            setLoginLoader={setLoginLoader}
                            setShowSidebar={setShowSidebar}
                            setIslogginIn={setIslogginIn}
                        />
                        <AuthenticationRouteILAdmin
                            exact
                            path="/auth/:refToken/:clientId/:role/:approute"
                            isInitCheck={isInitCheck}
                            setInitCheck={setInitCheck}
                            setLoginLoader={setLoginLoader}
                            setShowSidebar={setShowSidebar}
                            setIslogginIn={setIslogginIn}
                        />
                        <PublicRoute
                            isLoggingIn={isLoggingIn}
                            path={URLs.home}
                            exact
                            render={(props) => (
                                <SuspenseContainer>
                                    <Login
                                        {...props}
                                        loginLoader={loginLoader}
                                        setLoginLoader={setLoginLoader}
                                    />
                                </SuspenseContainer>
                            )}
                        />
                        <PublicRoute
                            isLoggingIn={isLoggingIn}
                            path={`${URLs.onboarding.profile}/:subToken`}
                            exact
                            render={(props) => (
                                <SuspenseContainer>
                                    <OnboardingProfileDetails {...props} />
                                </SuspenseContainer>
                            )}
                        />
                        <PublicRoute
                            isLoggingIn={isLoggingIn}
                            path={`${URLs.onboarding.company}/:reqToken`}
                            exact
                            render={(props) => (
                                <SuspenseContainer>
                                    <OnboardingCompanyDetails {...props} />
                                </SuspenseContainer>
                            )}
                        />
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={URLs.ARE.list}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.approvalRules}>
                                        <ApprovalRuleList
                                            {...props}
                                            setShowSidebar={setShowSidebar}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={URLs.ARE.create}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.approvalRules}>
                                        <NewApprovalRule {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.ARE.edit}/:id`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.approvalRules}>
                                        <NewApprovalRule {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={URLs.mastermind}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.mastermind}>
                                        <Mastermind {...props} />
                                    </AllowedApp>
                                )}
                            />
                        }
                        {
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={URLs.detailedSuggestion}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.mastermind}>
                                        <SuggestionDetailsPage {...props} />
                                    </AllowedApp>
                                )}
                            />
                        }

                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={URLs.policyEditor.flight.list}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.flightPolicy}>
                                        <FlightPolicyList {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={URLs.policyEditor.flight.create}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.flightPolicy}>
                                        <FlightPolicySingle {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.policyEditor.flight.edit}/:id`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.flightPolicy}>
                                        <FlightPolicySingle {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={URLs.policyEditor.hotel.list}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.hotelPolicy}>
                                        <HotelPolicyList {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={
                                    URLs.allSettings.companyDetailsSetting
                                        .default
                                }
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.allSettings}>
                                        <CompanyDetailsSetting {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={URLs.allSettings.reShopsSetting.default}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.allSettings}>
                                        <ReShopsSetting
                                            clientProfile={clientProfile}
                                            {...props}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.policyEditor.hotel.summary}/:ruleId`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.hotelPolicy}>
                                        <HotelPolicySummary {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={URLs.policyEditor.hotel.preferences}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.hotelPolicy}>
                                        <PreferredBlockedHotels
                                            clientProfile={clientProfile}
                                            {...props}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={URLs.policyEditor.hotel.create}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.hotelPolicy}>
                                        <HotelPolicySingle {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.policyEditor.hotel.edit}/:id`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.hotelPolicy}>
                                        <HotelPolicySingle {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.gettingStarted}`}
                                exact
                                render={(props) => (
                                    <AllowedApp
                                        route={appRoutes.gettingStarted}
                                    >
                                        <GettingStarted
                                            {...props}
                                            result={result}
                                            isLoading={isLoading}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.users.list}`}
                                exact
                                render={(props) => (
                                    <AllowedApp
                                        route={appRoutes.UserManagement}
                                    >
                                        <UsersList {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.users.create}`}
                                exact
                                render={(props) => (
                                    <AllowedApp
                                        route={appRoutes.UserManagement}
                                    >
                                        <UserCreation
                                            {...props}
                                            setShowSidebar={setShowSidebar}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.users.edit}/:userId`}
                                exact
                                render={(props) => (
                                    <AllowedApp
                                        route={appRoutes.UserManagement}
                                    >
                                        <UserCreation
                                            {...props}
                                            setShowSidebar={setShowSidebar}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.users.profile}`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.profile}>
                                        <UserCreation
                                            {...props}
                                            isProfile={true}
                                            setShowSidebar={setShowSidebar}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.users.customFields}`}
                                exact
                                render={(props) => (
                                    <AllowedApp
                                        route={appRoutes.UserManagement}
                                    >
                                        <CustomFields {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.users.departments}`}
                                exact
                                render={(props) => (
                                    <AllowedApp
                                        route={appRoutes.UserManagement}
                                    >
                                        <Departments {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.users.entity}`}
                                exact
                                render={(props) => (
                                    <AllowedApp
                                        route={appRoutes.UserManagement}
                                    >
                                        <Entity {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.users.businessUnit}`}
                                exact
                                render={(props) => (
                                    <AllowedApp
                                        route={appRoutes.UserManagement}
                                    >
                                        <BusinessUnit {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.users.employeeLevel}`}
                                exact
                                render={(props) => (
                                    <AllowedApp
                                        route={appRoutes.UserManagement}
                                    >
                                        <EmployeeLevel {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.users.bulkImport}/:jobId`}
                                exact
                                render={(props) => (
                                    <AllowedApp
                                        route={appRoutes.UserManagement}
                                    >
                                        <UserBulkUpload {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.users.bulkactionHistory}`}
                                exact
                                render={(props) => (
                                    <AllowedApp
                                        route={appRoutes.UserManagement}
                                    >
                                        <UserBulkActionHistory {...props} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.moreSettings.view}`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.moreSettings}>
                                        <MoreSettingsView
                                            {...props}
                                            result={result}
                                            isLoading={isLoading}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}

                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.integrations.list}`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.integrations}>
                                        <Integrations
                                            {...props}
                                            result={result}
                                            isLoading={isLoading}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {/* all settings -- travel settings */}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.allSettings.default}`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.allSettings}>
                                        <Settings
                                            {...props}
                                            result={result}
                                            isLoading={isLoading}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.allSettings.booking.default}`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.allSettings}>
                                        <BookingSetting
                                            {...props}
                                            result={result}
                                            isLoading={isLoading}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.allSettings.booking.bookForOther}`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.allSettings}>
                                        <BookingForOther
                                            {...props}
                                            result={result}
                                            isLoading={isLoading}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.allSettings.booking.hotelBookings}`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.allSettings}>
                                        <HotelBookings isLoading={isLoading} />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.allSettings.custom.default}`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.allSettings}>
                                        <CustomFieldsSettings
                                            {...props}
                                            result={result}
                                            isLoading={isLoading}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.allSettings.custom.addCustomFields}`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.allSettings}>
                                        <AddCustomFieldsSettings
                                            {...props}
                                            result={result}
                                            isLoading={isLoading}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.allSettings.custom.edit}`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.allSettings}>
                                        <EditCustomFieldsSettings
                                            {...props}
                                            result={result}
                                            isLoading={isLoading}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.allSettings.travelApprovals.default}`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.allSettings}>
                                        <TravelApprovals />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.paymentMethods.default}`}
                                exact
                                render={(props) => (
                                    <AllowedApp
                                        route={appRoutes.paymentMethods}
                                    >
                                        <PaymentMethods
                                            {...props}
                                            result={result}
                                            isLoading={isLoading}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.adminActions.signupRequestList}`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.adminActions}>
                                        <SignUpRequest
                                            {...props}
                                            setShowSidebar={setShowSidebar}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.allSettings.notifications.default}`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.allSettings}>
                                        <Notifications />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.landing.default}`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.landing}>
                                        <LandingPage
                                            {...props}
                                            setShowSidebar={setShowSidebar}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        {isInitCheck && (
                            <PrivateRoute
                                isLoggingIn={isLoggingIn}
                                path={`${URLs.trips.allTrips}`}
                                exact
                                render={(props) => (
                                    <AllowedApp route={appRoutes.allTrips}>
                                        <AllTrips
                                            {...props}
                                            setShowSidebar={setShowSidebar}
                                        />
                                    </AllowedApp>
                                )}
                            />
                        )}
                        <Route
                            render={(props) => (
                                <Page404 isInitCheck={isInitCheck} isLoggingIn={isLoggingIn} />
                            )}
                        />
                    </Switch>
                    {localStorage.getItem("token") && showSidebar && (
                        <Footer isInitCheck={isInitCheck} />
                    )}
                </AppWrapper>

                {localStorage.getItem("token") && !showSidebar && (
                    <Footer isInitCheck={isInitCheck} />
                )}
            </Router>
        </Fragment>
    );
}

export default Routes;
