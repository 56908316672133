import React from "react";
import "./Sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import { ListItem, ListItemText } from "@material-ui/core";

export default function MainMenu(props) {
  const location = useLocation();
  const { listItem, index, checkURLMatchByParts } = props;

  return (
    <>
      <Link to={listItem.url} key={index} className="sidebar__navlinks">
        <ListItem
          button
          selected={
            location.pathname === listItem.url ||
            location.pathname.indexOf(listItem.url) > -1 ||
            checkURLMatchByParts(location.pathname, listItem.subUrls)
          }
        >
          <ListItemText
            primary={listItem.liItemName}
            className="sidebar__navlinks-title"
          />
        </ListItem>
      </Link>
    </>
  );
}
