// Booking Page
export const BOOKING_ENABLE = "BOOKING_ENABLE";
export const BOOKING_DISABLE = "BOOKING_DISABLE";
export const FLIGHT_ENABLE = "FLIGHT_ENABLE";
export const FLIGHT_DISABLE = "FLIGHT_DISABLE";
export const HOTEL_ENABLE = "HOTEL_ENABLE";
export const HOTEL_DISABLE = "HOTEL_DISABLE";
export const CAR_ENABLE = "CAR_ENABLE";
export const CAR_DISABLE = "CAR_DISABLE";
export const BUS_ENABLE = "BUS_ENABLE";
export const BUS_DISABLE = "BUS_DISABLE";
export const TRAIN_ENABLE = "TRAIN_ENABLE";
export const TRAIN_DISABLE = "TRAIN_DISABLE";

export const SHOW_BOOKING_SKELETEN = "SHOW_BOOKING_SKELETEN";
export const HIDE_BOOKING_SKELETEN = "HIDE_BOOKING_SKELETEN";

export const SET_BOOKING_DETAILS = "SET_BOOKING_DETAILS";
export const SET_BANNER_MESSAGE = "SET_BANNER_MESSAGE";

export const FETCH_BOOKING_DETAILS = "FETCH_BOOKING_DETAILS";

// Book for other page
export const ADD_USER_IN_LIST = "ADD_USER_IN_LIST";
export const REMOVE_USER_IN_LIST = "REMOVE_USER_IN_LIST";
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_SELECTED_USER_LIST = "GET_SELECTED_USER_LIST";
export const GET_USER_LIST_PAGENATION = "GET_USER_LIST_PAGENATION";
export const GET_SELECTEDUSER_LIST_PAGENATION =
    "GET_SELECTEDUSER_LIST_PAGENATION";
export const GET_SEARCH_USER_LIST = "GET_SEARCH_USER_LIST";
export const GET_BOOK_FOR_OTHER = "GET_BOOK_FOR_OTHER";
export const POST_BOOK_FOR_OTHER = "POST_BOOK_FOR_OTHER";
export const HANDLE_CHANGE_BOOKING = "HANDLE_CHANGE_BOOKING";
export const SEARCH_IN_SELECTEDUSER = "SEARCH_IN_SELECTEDUSER";
export const SEARCH_FILTERED_USERS = "SEARCH_FILTERED_USERS";

// Custom Field Page
export const GET_CUSTOM_FIELD = "GET_CUSTOM_FIELD";
export const GET_CUSTOM_DIMENSION_CONFIG = "GET_CUSTOM_DIMENSION_CONFIG";
export const ADD_CUSTOM_FIELD = "ADD_CUSTOM_FIELD ";
export const UPDATE_CUSTOM_FIELD = "UPDATE_CUSTOM_FIELD ";
export const ADD_OPTION_FOR_SELECT = "ADD_OPTION_FOR_SELECT ";
export const UPDATE_OPTION_FOR_SELECT = "UPDATE_OPTION_FOR_SELECT ";
export const DELETE_OPTION_FOR_SELECT = "DELETE_OPTION_FOR_SELECT ";
export const TOGGLE_CUSTOM_FIELD = "TOGGLE_CUSTOM_FIELD ";
export const GETONE_CUSTOM_DIMENSION = "GETONE_CUSTOM_DIMENSION ";
export const GETONE_OPTIONS = "GETONE_OPTIONS ";
export const CLEAN_UP = "CLEAN_UP";
