import createReducer from '../../../utils/createReducer';

import { FETCH_FOOTER_LINKS, SET_FOOTER_LINKS } from '../../constants/common/Footer.type';

import { API_START, API_END, RESET_STATE } from '../../constants/common/Api.Types';

export const initialState = {
    footerLinks: [],
    isLoading: false,
	isLoadingData: null
}

export const reducerObj = {
    [SET_FOOTER_LINKS]: (prevState, payload) => (
        {
            footerLinks: payload.result
        }
    ),
    	// ------------- Api end and start loading state ----------
	[API_START]: (prevState, payload) => {
		if(payload === FETCH_FOOTER_LINKS){
            return { 
                isLoadingData : true
            }
        }
	},
	[API_END]: (prevState, payload) => {
		if(payload === FETCH_FOOTER_LINKS){
            return { 
                isLoadingData : false
            }
        }
	}
}

const FooterReducer = createReducer(initialState, reducerObj);

export default FooterReducer;