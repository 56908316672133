import createReducer from "../../../utils/createReducer";
import { API_START, API_END } from "../../constants/common/Api.Types";
import {
	FETCH_AIRLINES,
	FETCH_AIRPORTS,
	FETCH_GRADES,
	SET_AIRLINES,
	SET_AIRPORTS_DATA,
	SET_GRADES,
} from "../../constants/common/StaticAPIData.types";

const initialState = {
	isLoading: false,
	client: null,
	grades: null,
	airports: null,
	airlines: null,
	countryCodes: null,
};

export const reducerObj = {
	[API_START]: (prevState, payload) => {
		if (
			payload === FETCH_GRADES ||
			payload === FETCH_AIRPORTS ||
			payload === FETCH_AIRLINES
		) {
			return {
				isLoading: true,
			};
		}
	},
	[API_END]: (prevState, payload) => {
		if (
			payload === FETCH_GRADES ||
			payload === FETCH_AIRPORTS ||
			payload === FETCH_AIRLINES
		) {
			return {
				isLoading: false,
			};
		}
	},
	[SET_GRADES]: (prevState, payload) => {
		return {
			grades: payload.res.result.levels,
			client: payload.client,
		};
	},
	[SET_AIRPORTS_DATA]: (prevState, payload) => {
		return {
			airports: payload.result,
		};
	},
	[SET_AIRLINES]: (prevState, payload) => {
		return {
			airlines: payload.result,
		};
	},
};

const StaticAPIDataReducer = createReducer(initialState, reducerObj);

export default StaticAPIDataReducer;
