export const appRoutes = {
    mastermind: "mastermind",
    approvalRules: "approval-rules",
    flightPolicy: "flight-policy",
    hotelPolicy: "hotel-policy",
    gettingStarted: "getting-started",
    UserManagement: "user-management",
    profile: "profile",
    integrations: "integrations",
    moreSettings: "more-settings",
    adminActions: "admin-actions",
    allSettings: "all-settings",
    paymentMethods: "payment-methods",
    landing: "admin-home",
    approvalsPending: "approvals-pending",
    allTrips: "all-trips",
};
