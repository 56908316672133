import { Redirect } from "react-router-dom";
import LocalStorageService from "../services/LocalStorage";
import { commonUrls } from "./urls/apiUrls";
import { services } from "@itilite/utils";


const { default: axios } = require("axios");
const moment = require("moment");

async function validateAccessToken() {

	if (localStorage.getItem('token')) {

		let tokenObj = JSON.parse(localStorage.getItem('token'))
		let accessToken = tokenObj.access
		let exp = tokenObj.acc_exp_in;


		let currTimeUnix = moment().unix(); // secs

		if (currTimeUnix >= exp) {
			let refreshToken = tokenObj.refresh;
			let resfreshTokenExp = tokenObj.ref_exp_in;
			const { IUILocalStorageService } = services;

			if (currTimeUnix <= resfreshTokenExp) {
				await axios({
					method: 'POST',
					url: commonUrls.FETCH_REFRESH_TOKEN,
					data: {
						refresh: refreshToken
					}
				})
					.then(res => {
						const _token = res.data.token;
						accessToken = _token.access
						LocalStorageService.setAccessToken(_token.access);
						IUILocalStorageService.setAccessToken(_token.access);
						IUILocalStorageService.setRefreshToken(_token.refresh);
						localStorage.setItem("token", JSON.stringify(_token));
					})
					.catch(error => {
						console.log(error)
					})
			} else {
				localStorage.clear();
				window.location.href = '/'
			}
		}
		return accessToken
	}
	localStorage.clear();
	window.location.href = '/'
}

export { validateAccessToken };