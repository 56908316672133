import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Link } from "@material-ui/core";
// import footerJSON from './Footer.json';  // static footer JSON
import { v4 as uuidv4 } from "uuid";
import "./Footer.scss";
import { fetchFooterLinks } from "../../redux/actions/common/Footer.action";
import { validateAccessToken } from "../../utils/validateAccessToken";
import { useTranslation } from "react-i18next";

function Footer({ isInitCheck }) {
    const { footerLinks, isLoadingData } = useSelector(
        (state) => state.FooterReducer
    );
    const dispatch = useDispatch();
    const { t } = useTranslation();
    useEffect(() => {
        // let accessToken = validateAccessToken()
        if (isInitCheck) {
            dispatch(fetchFooterLinks());
        }
    }, [isInitCheck]);

    // console.log('footer>>>', footerLinks)
    return (
        isLoadingData === false && (
            <div className="footer">
                <Grid container spacing={0}>
                    <Grid item sm={6} xs={12}>
                        <div className="footer__left">
                            {footerLinks.left?.map((footerItem) => {
                                if (footerItem.name === "copyrights") {
                                    return (
                                        <React.Fragment key={uuidv4()}>
                                            <div className="footer__borderRight">
                                                <span>© &nbsp; &nbsp;</span>
                                                {t(
                                                    "ITILITE. All Rights Reserved"
                                                )}
                                            </div>
                                        </React.Fragment>
                                    );
                                } else {
                                    return (
                                        <Link
                                            key={uuidv4()}
                                            href={footerItem.link}
                                            className={
                                                footerItem.name !== "policy"
                                                    ? "footer__borderRight"
                                                    : ""
                                            }
                                        >
                                            {t(`${footerItem.display_name}`)}
                                        </Link>
                                    );
                                }
                            })}
                        </div>
                    </Grid>
                    <Grid item sm={6} xs={12} className="footer__right">
                        <div className="footer__right">
                            {footerLinks.right?.map((footerItem) => {
                            if (footerItem.custom_support_flag === 1) {
								if (footerItem.name === "support_email") {
									return (
										<div
											key={uuidv4()}
											className='footer__borderRight'
										>
											Contact : {footerItem.display_name}{" "}
										</div>
									);
								} else if (
									footerItem.name === "support_mobile"
								) {
									return (
										<span key={uuidv4()}>
											{footerItem.display_name}
										</span>
									);
								} else {
									return null;
								}
                            }
                            if (footerItem.custom_support_flag === 0) {
                                return (<span key={uuidv4()}>
                                {footerItem.custom_support_txt}{" "}
                                <Link className="footer__support__link"
                                    key={uuidv4()}
                                    target="_blank"
                                    href={footerItem.custom_support_link}
                                >
                                    {footerItem.custom_support_button_txt}
                                </Link>
                                </span>);
                            }
							})}
                            {/* <span key={uuidv4()}>
                                Reach our 24x7 support center{" "}
                                <Link
                                    key={uuidv4()}
                                    target="_blank"
                                    href="https://www.itilite.com/contact-us/"
                                >
                                    {"here"}
                                </Link>
                            </span> */}
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    );
}

export default Footer;
