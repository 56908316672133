import { appRoutes } from "./appRoutes";

export const roleBasedPermission = {
    [appRoutes.mastermind]: ["client", "admin"],
    [appRoutes.approvalRules]: ["client", "admin"],
    [appRoutes.flightPolicy]: ["client", "admin"],
    [appRoutes.hotelPolicy]: ["client", "admin"],
    [appRoutes.gettingStarted]: ["client", "admin"],
    [appRoutes.allTrips]: ["client", "admin"],
    [appRoutes.UserManagement]: ["client", "admin"],
    [appRoutes.profile]: ["client", "admin", "traveller"],
    [appRoutes.integrations]: ["client", "admin"],
    [appRoutes.moreSettings]: ["client", "admin"],
    [appRoutes.adminActions]: ["admin"],
    [appRoutes.allSettings]: ["client", "admin"],
    [appRoutes.companyDetailsSetting]: ["client", "admin"],
    [appRoutes.reShopsSetting]: ["client", "admin"],
    [appRoutes.paymentMethods]: ["client"],
    [appRoutes.landing]: ["client"],
};
