export const INPUT_VALUE = "INPUT_VALUE";

export const SELECT_VALUE = "SELECT_VALUE";

export const COUNTRY_VALUE = "COUNTRY_VALUE";

export const ENABLE_TERMS = "ENABLE_TERMS";

export const USER_DETAILS = "USER_DETAILS";

export const SET_STEP = "SET_STEP";

export const SET_REQ_TOKEN = "SET_REQ_TOKEN";

export const UPDATE_ERRORS = "UPDATE_ERRORS";

export const UPDATE_ERROR_MSG = "UPDATE_ERROR_MSG";

export const FETCH_GEOLOCATION = "FETCH_GEOLOCATION";

export const SET_GEOLOCATION = "SET_GEOLOCATION";

export const SET_COUNTRY_CODE_LIST = "SET_COUNTRY_CODE_LIST";

export const UPDATE_COUNTRY_NAME = "UPDATE_COUNTRY_NAME";

export const PASSWORD_SHOW_CHANGE = "PASSWORD_SHOW_CHANGE";
