import { SET_TRAVEL_APPROVAL_CONFIGS, UPDATE_TRAVEL_APPROVAL_CONFIG } from "../../../../constants/IL_Admin/AllSettings/TravelApprovals.types";

const initialState = {
    client_id: "",
    enable_trip_approval_reason_config : 0,
    enable_trip_rejection_reason_config: 0,
    enable_cost_approval_reason_config: 0,
    enable_cost_rejection_reason_config: 0,
    enable_high_cost_approval_reason_config: 0,
    enable_high_cost_rejection_reason_config: 0,
    rules_count: null
}

const TravelApprovals = function reducerObj(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_TRAVEL_APPROVAL_CONFIGS : {
            return {
              ...state,
              ...payload
            }
          } 
        case UPDATE_TRAVEL_APPROVAL_CONFIG: {
            return {
                ...state,
                ...payload
            }
        }
        default:
            return state
    }
}

export default TravelApprovals