import React from "react";
import { Route, Redirect } from "react-router-dom";
import LocalStorageService from "../../services/LocalStorage";

function PrivateRoute(props) {
    if (!LocalStorageService.getAccessToken()) {
        return <Redirect to='/' />
    }
    return <Route { ...props } />
}

export default PrivateRoute;
