import URLs from "./urls/routeUrls";

function routeUtils(route) {
    let returnRoute = "";
    switch (route) {
        case "mastermind":
            returnRoute = "/mastermind";
            break;
        case "approval-rules":
            returnRoute = "/approval-rules";
            break;
        case "flight-policy":
            returnRoute = URLs.policyEditor.flight.list;
            break;
        case "hotel-policy":
            returnRoute = URLs.policyEditor.hotel.list;
            break;
        case "getting-started":
            returnRoute = URLs.gettingStarted;
            break;
        case "user-management":
            returnRoute = URLs.users.list;
            break;
        case "profile":
            returnRoute = URLs.users.profile;
            break;
        case "integrations":
            returnRoute = URLs.integrations.list;
            break;
        case "more-settings":
            returnRoute = URLs.moreSettings.view;
            break;
        case "admin-actions":
            returnRoute = URLs.adminActions.signupRequestList;
            break;
        case "all-settings":
            returnRoute = URLs.allSettings.default;
            break;
        case "payment-methods":
            returnRoute = URLs.paymentMethods.default;
            break;
        case "admin-home":
            returnRoute = URLs.landing.default;
            break;
        case "approvals-pending":
            returnRoute = URLs.approvalsPending;
            break;
        case "all-trips":
            returnRoute = URLs.trips.allTrips;
            break;
        case "profile?required=dob":
        case "profile?required=card":
            returnRoute = "/" + route;
            break;
        default:
            returnRoute = null;
            break;
    }
    return returnRoute;
}

export { routeUtils };
