import React, { useState, useEffect } from "react";
import "./index.css";
import Routes from "./routes/Routes";
import { BrowserRouter as Router } from "react-router-dom";
import "./theming/__globals.scss";
import { StylesProvider } from "@material-ui/core/styles"; // <-- import this component, and wrap your App.
import "@itilite/iuistyles/dist/index.css";
import "@itilite/iuistyles/dist/classes.css";
// import "@itilite/iuistyles/dist/style.css";

function App() {
  const [isAuth] = useState(false);

  /** ----authentication check---- */
  useEffect(() => {}, []);
  return (
    <StylesProvider injectFirst>
      <div className="App">
        <Router getUserConfirmation={() => {}}>
          <Routes isAuth={isAuth} />
        </Router>
      </div>
    </StylesProvider>
  );
}

export default App;
