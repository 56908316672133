import createReducer from '../../../utils/createReducer';

import { GET_GETTING_STARTED_WITH, SET_GETTING_STARTED_WITH } from '../../constants/GettingStartedWith/GettingStartedWith.type';

import { API_START, API_END, RESET_STATE } from '../../constants/common/Api.Types';



export const initialState = {
    result: {},
    isLoading: false
}


export const reducerObj = {
    [SET_GETTING_STARTED_WITH]: (prevState, payload) => (
        {
            result: payload.result
        }
    ),
    // ------------- Api end and start loading state ----------
    [API_START]: (prevState, payload) => {
        if (payload === GET_GETTING_STARTED_WITH) {
            return {
                isLoadingData: true
            }
        }
    },
    [API_END]: (prevState, payload) => {
        if (payload === SET_GETTING_STARTED_WITH) {
            return {
                isLoadingData: false
            }
        }
    }
}

const GettingStartedWithReducer = createReducer(initialState, reducerObj);

export default GettingStartedWithReducer;