import React from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import LocalStorageService from "../../services/LocalStorage";
import axios from "axios";
import { saveClientId } from "../../redux/actions/common/Auth.action";
import { services } from "@itilite/utils";
import { commonUrls } from "../../utils/urls/apiUrls";
import URLs from "../../utils/urls/routeUrls";
import { saveClientUSM } from "../../redux/actions/common/Auth.action";
import { appRoutes } from "../../utils/appRoutes";

function AuthenticationRouteILAdmin(props) {
    const { IUILocalStorageService } = services;
    const dispatch = useDispatch();

    localStorage.clear(); // !clearing old sessions

    if (
        props.computedMatch.params.refToken &&
        props.computedMatch.params.clientId &&
        props.computedMatch.params.role &&
        props.computedMatch.params.approute
    ) {
        const refreshToken = props.computedMatch.params.refToken;
        let clientId = props.computedMatch.params.clientId;
        let role = props.computedMatch.params.role;
        let route = props.computedMatch.params.approute;

        // //console.log(refreshToken, role, route, clientId,"IL Admin");

        dispatch(saveClientId(clientId));
        props.setIslogginIn(true);
        /** to get the access token with refresh token */
        axios({
            method: "POST",
            url: commonUrls.FETCH_REFRESH_TOKEN,
            data: {
                refresh: refreshToken,
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const _token = res.data.token;
                    const _showSidebar =
                        route !== "profile" && route !== appRoutes.allTrips;
                    LocalStorageService.setAccessToken(_token.access);
                    LocalStorageService.setRole(role);
                    IUILocalStorageService.setAccessToken(_token.access);
                    IUILocalStorageService.setRefreshToken(_token.refresh);
                    localStorage.setItem("redirectedTo", true);
                    localStorage.setItem("token", JSON.stringify(_token));
                    localStorage.setItem("client_id", clientId);
                    localStorage.setItem("app", route);

                    props.setInitCheck(true);
                    props.setShowSidebar(_showSidebar);
                    dispatch(saveClientUSM(res.data));
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    LocalStorageService.removeAllToken();
                    props.setInitCheck(true);
                    props.setLoginLoader(false);
                }
            });

        return (
            <Redirect to={{ pathname: URLs.home, state: { loading: true } }} />
        );
    } else {
        LocalStorageService.removeAllToken();
        return (
            <Redirect to={{ pathname: URLs.home, state: { loading: false } }} />
        );
    }
}

export default AuthenticationRouteILAdmin;
