export const fontPrimary = `"Roboto", sans-serif`;
export const fontSecondary = `"Inter", sans-serif`;

export const primaryMain = "#e95d25";
export const primaryLight = "#dd6b20";

export const fontBlack = "#000000";
export const fontDark = "#1a202c";
export const fontMediumdark = "#27272A";
export const fontMedium = "#4a5568";
export const fontLight = "#767676";
export const fontExtralight = "#b0b0b0";
export const fontWhite = "#ffffff";
export const fontLink = "#2c5282";
export const fontError = "red";

export const iconColor = "#a0aec0";

export const backgroundExtraextralight = "#fafafa";
export const backgroundExtralight = "#f1f1f1";
export const backgroundMediumlight = "#dddddd";
export const backgroundLight = "#767676";
export const backgroundMedium = "#4a5568";
export const backgroundWhite = "#ffffff";

export const fontWeightLight = 300;
export const fontWeightNormal = 400;
export const fontWeightSemibold = 500;
export const fontWeightBold = 700;

export const fontBaseline = "1em";

export const textxs = fontBaseline * 0.75;
export const textsm = fontBaseline * 0.85;
export const textmd = fontBaseline;
export const textlg = fontBaseline * 1.15;
export const textxl = fontBaseline * 1.25;
export const textxxl = fontBaseline * 1.5;
export const textx3l = fontBaseline * 1.75;
export const textx4l = fontBaseline * 2;

export const spacer = "1em";

export const baseline = "24px";

/* spacing values */
export const spacexs = baseline / 4;
export const spacesm = baseline / 2;
export const spacemd = baseline;
export const spacelg = baseline * 2;
export const spacexl = baseline * 3;

export const boxShadow1 =
	"0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)";
