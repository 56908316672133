// import the all url related to headers

import { GET_GETTING_STARTED_WITH, SET_GETTING_STARTED_WITH } from '../../constants/GettingStartedWith/GettingStartedWith.type';

// import { RESET_STATE } from '../../constants/common/Api.Types';

// importing action creator
import actionCreator from '../common/Util';

import { gettingStartedWith } from '../../../utils/urls/apiUrls';


/** ----- FETCHING THE HEADER DETAILS------ */
export const getGettingStartedWith = (token, method, data, role) => {
	return actionCreator({
		method: "POST",
		data,
		// url: `${process.env.REACT_APP_BASE_URL}/api/v1/nav_bar/header/`,
		url: gettingStartedWith.GET_GETTING_STARTED_WITH,
		onSuccess: setGettingStartedWith,
		accessToken: token,
		onFailure: () => console.log("Failed to fetch Data."),
		label: GET_GETTING_STARTED_WITH
	});

	function setGettingStartedWith(res) {
		return {
			type: SET_GETTING_STARTED_WITH,
			payload: res
		}
	}
}