import __globals from "./__globals";
const {
	breakpoints,
} = __globals;

const __override = {
	body: {
		fontSize: 14,
	},
	MuiAppBar: {
		colorPrimary: {
			backgroundColor: "#fff",
		},
	},
	MuiLink: {
		root: {
			color: __globals.palette.secondary.dark,
		},
	},
	MuiButton: {
		root: {
			fontSize: "14px",
			lineHeight: "150%",
			"&:hover": {
				backgroundColor: "rgba(233, 93, 37, 0.9)",
			},
			textTransform: "none",
			"&$disabled": {
				color: "#ffffff",
				opacity: 0.7,
			},
			boxShadow: "none",
		},
		contained: {
			boxShadow: "none",
			height: 40,

			"&:hover": {
				boxShadow:
					__globals.shadows[3]
			},
		},
		containedPrimary: {
			"&:hover": {
				backgroundColor: __globals.palette.primary.main,
			},
		},
		text: {
			color: "white",
			background: __globals.palette.primary.main,
			borderRadius: 6,
			textTransform: "none",
			padding: "8px 16px",
		},
	},
	MuiAutocomplete: {
		tag: {
			margin: "3px 3px 3px 0",
		},
		endAdornment:{
			top: "calc(100% - 8px)",
			transform: "translateY(-100%)"
		}
	},
	MuiInputBase: {
		root: {
			fontSize: "14px",
			[breakpoints.up("xl")]: {
				fontSize: "16px",
			},
		},
	},
	MuiTypography: {
		body1: {
			fontSize: 14,
			[breakpoints.up("xl")]: {
				fontSize: 16,
			},
		},
	},
	MuiButtonBase: {
		// disableRipple: true, // No more ripple, on the whole application!
	},
	MuiAvatar: {
		img: {
			height: 30,
			width: 30,
			[breakpoints.up("xl")]: {
				height: 33,
				width: 33,
			},
		},
	},
};

export default __override;
