import { combineReducers } from "redux"; //importing combine reducer for all reducer combining

// importing all reducers
import AuthReducer from "./common/Auth.reducer";
import HeaderReducer from "./common/Header.reducer";
import FooterReducer from "./common/Footer.reducer";
import StaticAPIDataReducer from "./common/StaticAPIData.reducer";
import RuleListReducer from "../reducers/IL_Admin/Approvals/RuleList/RuleList.reducer";
import CreateApprovalReducer from "../reducers/IL_Admin/Approvals/CreateApproval/CreateApproval.reducer";
import UserDetailsReducer from "../reducers/IL_Admin/Onboarding/UserDetails.reducer";
import CompanyDetailsReducer from "../reducers/IL_Admin/Onboarding/CompanyDetails.reducers";
import MastermindReducer from "./Mastermind/Mastermind.reducer";
import FlightPolicyListReducer from "./IL_Admin/PolicyEditor/List/FlightPolicyList.reducer";
import FlightPolicySingleReducer from "./IL_Admin/PolicyEditor/Single/FlightPolicySingle.reducer";
import ToastServicesReducer from "./common/Services/ToastServices.reducer";
import GettingStartedWithReducer from "./GettingStartedWith/GettingStartedWith.reducer";
import UserIntegrations from "./Integrations/UserIntegrations.reducer";
import OtherIntegrations from "./Integrations/OtherIntegrations.reducer";
import BookForOtherReducer from "./IL_Admin/AllSettings/BookForOthers/BookForOthers.reducer";
import HotelPolicyListReducer from "./IL_Admin/PolicyEditor/List/HotelPolicyList.reducer";
import HotelPolicySingleReducer from "./IL_Admin/PolicyEditor/Single/HotelPolicySingle.reducer";
import CustomFieldsReducer from "./IL_Admin/AllSettings/CustomFleids/CustomFields.reducer";
import PreferredBlockedHotelsReducer from "./IL_Admin/PolicyEditor/PreferredBlockedHotels/PreferredBlockedHotels.reducer";
import AllTripsReducer from "./IL_Admin/AllTrips/index";

// All settings
import BookingSettings from "./IL_Admin/AllSettings/Bookings/Bookings.reducer";
import TravelApprovals from "./IL_Admin/AllSettings/TravelApprovals/TravelApprovals.reducer";
import NotificationsReducer from "./IL_Admin/AllSettings/Notifications/Notifications.reducer";

// payment card details
import CardDetailsReducer from "./IL_Admin/PaymentMethods/PaymentMethods.reducer";
// creating a combined reducers
const reducer = combineReducers({
    AuthReducer,
    HeaderReducer,
    FooterReducer,
    ToastServicesReducer,
    RuleListReducer,
    CreateApprovalReducer,
    UserDetailsReducer,
    CompanyDetailsReducer,
    MastermindReducer,
    StaticAPIDataReducer,
    FlightPolicyListReducer,
    FlightPolicySingleReducer,
    GettingStartedWithReducer,
    UserIntegrations,
    OtherIntegrations,
    BookingSettings,
    BookForOtherReducer,
    TravelApprovals,
    CardDetailsReducer,
    HotelPolicyListReducer,
    HotelPolicySingleReducer,
    PreferredBlockedHotelsReducer,
    CustomFieldsReducer,
    NotificationsReducer,
    AllTripsReducer,
});

export default reducer;
