import { FETCH_AUTH_DETAILS, SET_AUTH_DETAILS, SAVE_CLIENT_ID, SAVE_CLIENT_ID_IN_AUTH, FETCH_CLIENT_PROFILE, SAVE_CLIENT_PROFILE, SET_CLIENT_SIDEBAR_MENUS, SET_CLIENT_USM_DETAILS } from '../../constants/common/Auth.types';

import { API_START, API_END } from "../../constants/common/Api.Types";

// import new reducer
import createReducer from "../../../utils/createReducer";

const initialState = {
	authDetails: {},
	cid: '',
	isLoading: false,
	isIlAdmin: false,
	clientProfile: {},
	clientUSM: {},
	sidebarMenuPermissions: []
}

export const reducerObj = {
	[SET_AUTH_DETAILS]: (prevState, payload) => (
		{
			authDetails: payload
		}
	),

	[SAVE_CLIENT_ID]: (prevState, payload) => (
		{
			cid: payload
		}
	),

	[SAVE_CLIENT_ID_IN_AUTH]: (prevState, payload) => (
		{
			authDetails: { client: payload }
		}
	),

	[SAVE_CLIENT_PROFILE]: (prevState, payload) => (
		{
			clientProfile: payload
		}
	),
	[SET_CLIENT_SIDEBAR_MENUS]: (prevState, payload) => (
		{
			sidebarMenuPermissions: payload.result
		}
	),
	[SET_CLIENT_USM_DETAILS]: (prevState, payload) => (
		{
			clientUSM: payload
		}
	),
	
	/** --- Api end and start loading state --- */
	[API_START]: (prevState, payload) => {
		if (payload === FETCH_AUTH_DETAILS || payload === FETCH_CLIENT_PROFILE) {
			return {
				isLoading: true
			}
		}
	},
	[API_END]: (prevState, payload) => {
		if (payload === FETCH_AUTH_DETAILS || payload === FETCH_CLIENT_PROFILE) {
			return {
				isLoading: false
			}
		}
	}
}

const AuthReducer = createReducer(initialState, reducerObj);

export default AuthReducer;