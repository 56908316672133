// import the all url related to headers

import { FETCH_FOOTER_LINKS, SET_FOOTER_LINKS } from '../../constants/common/Footer.type';

import { RESET_STATE } from '../../constants/common/Api.Types';

// importing action creator
import actionCreator from '../common/Util';

import { commonUrls } from '../../../utils/urls/apiUrls';


/** ----- FETCHING THE HEADER DETAILS------ */
export const fetchFooterLinks = (token, method, data) => {
    return actionCreator({
        method,
        data,
        // url: 'http://127.0.0.1:5000/api/v1/nav_bar/footer/',
        // url: `${process.env.REACT_APP_BASE_URL}/api/v1/nav_bar/footer/`,
        url: commonUrls.FOOTER,
        onSuccess: setFooterLinks,
        accessToken: token,
        onFailure: () => console.log("Failed to fetch Data."),
        label: FETCH_FOOTER_LINKS
    });

    function setFooterLinks(res) {
        return {
            type: SET_FOOTER_LINKS,
            payload: res
        }
    }
}