// importing all types
import {
    FETCH_INPUT_VARS,
    FETCH_DEPT,
    FETCH_GRADE,
    FETCH_APPROVAL_LABEL,
    SET_INPUT_VARS,
    SET_DEPT,
    SET_GRADE,
    SET_APPROVAL_LABEL,
    CREATE_APPROVAL_RULE,
    SET_CREATE_APPROVAL_RULE,
    UPDATE_DROPDOWN_VALUE,
    UPDATE_MULTISELECT_VALUE,
    UPDATE_RADIO_VALUE,
    UPDATE_CHECKBOX_VALUE,
    ADD_APPROVER,
    REMOVE_APPROVER,
    SET_APPROVERS,
    UPDATE_OPERATOR_DROPDOWN_VALUE,
    UPDATE_INPUT_VALUE,
    UPDATE_CLICK_VALUE,
    ADD_CLONE_VALUE,
    REMOVE_CLONE_VALUE,
    UPDATE_CLONE_TEXT_VALUE,
    UPDATE_HIDDEN_VALUE,
    UPDATE_MODES_ACTIVE_VARS,
    UPDATE_CLONE_CHILD_VALUE,
    UPDATE_RULE,
    UPDATE_RULE_ATTRIBUTES,
    UPDATE_ERROR,
    SET_UPDATE_RULE,
    FILL_INPUT_FIELD_TO_UPDATE_RULE,
    SET_AIRPORTS,
    FETCHING_AIRPORTS,
    UPDATE_FOURTHSTEP_CHECKBOX,
    UPDATE_COMPANY_DEFINED_FIELDS,
    SET_ACTIVE_STEP,
} from "../../../../constants/IL_Admin/Approvals/CreateApproval/CreateApproval.types";
import {
    API_START,
    API_END,
    RESET_STATE,
} from "../../../../constants/common/Api.Types";

// import new reducer
import createReducer from "../../../../../utils/createReducer";

// initialState
// export const initialState = {
//     inputVars: [],
//     department: [],
//     grade: [],
//     isLoading: false,
//     message: '',
//     createdRule: [],
//     createRuleBody: {
//         "active": true,
//         "priority": 6,
//         "approval_flow": "sequence",
//         "rule_desc": "Flight__222",
//         "rule_type": "Travel",
//         "client_id": 810,
//         // "json": {}
//     },
//     __ruleAttribute: [],
//     relationMap: [],
//     customApiData: {}
// }

// // create approval reducer
// export const reducerObj = {
//     [SET_INPUT_VARS]: (prevState, payload) => (
//         {
//             inputVars: payload.inputVars,
//             relationMap: payload.relationMap,
//             __ruleAttribute: payload.ruleAttributes,
//             // createRuleBody: {
//             //     ...prevState.createRuleBody,
//             //     json: {
//             //         rule_attribute: payload.ruleAttributes
//             //     }
//             // }
//         }
//     ),

//     [SET_DEPT]: (prevState, payload) => (
//         {
//             department: payload.result
//         }
//     ),

//     [SET_GRADE]: (prevState, payload) => (
//         {
//             grade: payload.result
//         }
//     ),

//     [SET_CREATE_APPROVAL_RULE]: (prevState, payload) => (
//         {
//             createdRule: payload
//         }
//     ),

//     [UPDATE_DROPDOWN_VALUE]: (prevState, payload) => (
//         {
//             __ruleAttribute: [
//                 ...prevState.__ruleAttribute,
//                 // {
//                 //     ...prevState.__ruleAttribute[payload.id - 1],
//                 //     operator: Symbol
//                 // }
//                 // prevState.__ruleAttributes : {
//                 //     [id - 1]: {
//                 //         operator: operator
//                 //     }
//                 // }
//             ]
//         }
//     ),

//     /**-----------Api start and end state----- */
//     [API_START]: (prevState, payload) => (
// 		payload === FETCH_INPUT_VARS || FETCH_DEPT || FETCH_GRADE || CREATE_APPROVAL_RULE
// 			? { isLoading: true }
// 			: { isLoading: false }
// 	),
// 	[API_END]: (prevState, payload) => (
// 		payload === FETCH_INPUT_VARS || FETCH_DEPT || FETCH_GRADE || CREATE_APPROVAL_RULE
// 			? { isLoading: false }
// 			: { isLoading: false }
// 	),
// }

// const CreateApprovalReducer = createReducer(initialState, reducerObj);

// export default CreateApprovalReducer;

const initialState = {
    inputVars: [],
    department: [],
    grade: [],
    airports: [],
    isLoading: true,
    message: "",
    createdRule: [],
    createRuleBody: {
        active: true,
        priority: 6,
        approval_flow: "sequence",
        rule_desc: "Flight__222",
        rule_type: "Travel",
        client_id: 810,
        // "json": {}
    },
    __ruleAttribute: [],
    defaultRuleAttribute: [],
    relationMap: [],
    customApiData: {},
    approval_attributes: [
        {
            approval_1: [],
            display: "Tier #1",
            tier: 1,
            spc_email: [],
            hod: [],
            manager: [],
        },
    ],
    approversList: [],
    specificApprovers: [],
    is_manager: 0,
    is_hod: 0,
    seq_ids: [],
    hiddenVars: [],
    modesActiveVars: {},
    ignoreVarsList: {},
    ignoreVars: [],
    cloneArray: [],
    activeStep: 1,
    approvals_verification: [],
};

export default function reducerObj(state = initialState, action) {
    // console.log(action)
    const { type, payload } = action;

    switch (type) {
        case SET_ACTIVE_STEP:
            return {
                ...state,
                activeStep: payload,
            };
        case SET_INPUT_VARS:
            // console.log("SET_INPUT_VARS", payload);
            let __tempRulesAttrs = JSON.parse(
                JSON.stringify(payload.ruleAttributes)
            );
            return {
                ...state,
                inputVars: payload.inputVars,
                relationMap: payload.relationMap,
                __ruleAttribute: [...payload.ruleAttributes],
                defaultRuleAttribute: __tempRulesAttrs,
                createRuleBody: {
                    ...state.createRuleBody,
                    json: {
                        rule_attribute: __tempRulesAttrs,
                    },
                },
                isLoading: false,
                hiddenVars: payload.hiddenVars,
                approval_attributes: [
                    {
                        approval_1: [],
                        display: "Tier #1",
                        tier: 1,
                        spc_email: [],
                        error: false,
                        error_text: "",
                    },
                ],
                modesActiveVars: payload.modesActiveVars,
                ignoreVarsList: payload.ignoreVarsList,
                ignoreVars: payload.ignoreVars,
                cloneArray: payload.cloneArray,
                approvals_verification: payload.approvals_verification,
            };

        case SET_DEPT:
            return {
                ...state,
                department: payload.result,
            };

        case SET_GRADE:
            return {
                ...state,
                grade: payload.result,
            };

        case SET_AIRPORTS:
            return {
                ...state,
                airports: payload.result,
            };
        case SET_APPROVAL_LABEL:
            const _seq_ids = Object.keys(payload.result.approvers);
            const _approverList = payload.result.approvers;
            const _specificApprovers = payload.result.specific_approvers;
            const _is_manager = payload.result.is_manager;
            const _is_hod = payload.result.is_hod;
            // console.log(_seq_ids,_approverList);
            return {
                ...state,
                approversList: _approverList,
                seq_ids: _seq_ids,
                specificApprovers: _specificApprovers,
                is_hod: _is_hod,
                is_manager: _is_manager,
            };

        case SET_CREATE_APPROVAL_RULE:
            return {
                ...state,
                createdRule: payload,
                // isRuleCreated: true,
                // reset
                __ruleAttribute: state.defaultRuleAttribute,
                approval_attributes: initialState.approval_attributes,
            };

        case UPDATE_OPERATOR_DROPDOWN_VALUE:
            let __ruleAttributeOperatorDropdown = [...state.__ruleAttribute];
            let __tempOperatorObj =
                __ruleAttributeOperatorDropdown[payload.parentId - 1];
            // console.log(
            // 	"UPDATE_OPERATOR_DROPDOWN_VALUE",
            // 	__tempOperatorObj,
            // 	payload
            // );
            __tempOperatorObj.operator = payload.symbol;
            return {
                ...state,
                __ruleAttribute: __ruleAttributeOperatorDropdown,
            };

        case UPDATE_DROPDOWN_VALUE:
            let __ruleAttributeDropdown = [...state.__ruleAttribute];
            let __IgnoreVars = [...state.ignoreVars];
            let __IgnoreVarsList = { ...state.ignoreVarsList };
            let __tempObj = __ruleAttributeDropdown[payload.parentId - 1];
            const removeDuplicate = (v) => {
                let __index = __IgnoreVars.indexOf(v);
                if (__index > -1) {
                    __IgnoreVars.splice(__index, 1);
                }
                if (__IgnoreVars.indexOf(v) !== -1) removeDuplicate(v);
            };
            for (const [key, value] of Object.entries(
                __IgnoreVarsList[payload.parentId]
            )) {
                if (key === payload.childId) {
                    value.forEach((v) => {
                        removeDuplicate(v);
                    });
                } else {
                    value.forEach((v) => {
                        if (__IgnoreVars.indexOf(v) === -1) {
                            __IgnoreVars.push(v);
                        }
                    });
                }
            }
            __tempObj.data = [
                {
                    id: parseInt(payload.childId),
                    var_name: payload.varName,
                    allow_any:
                        payload.allow_any === "true" ||
                        payload.allow_any === true
                            ? true
                            : false,
                    display_value_html: payload.display_value_html,
                },
            ];
            return {
                ...state,
                __ruleAttribute: __ruleAttributeDropdown,
                ignoreVars: __IgnoreVars,
            };
        case UPDATE_MULTISELECT_VALUE:
            let __ruleAttributeMultiSelect = [...state.__ruleAttribute];
            let __tempObjMulti = __ruleAttributeMultiSelect[payload.id - 1];
            __tempObjMulti.data = [...payload.options];
            return {
                ...state,
                __ruleAttribute: __ruleAttributeMultiSelect,
            };

        case UPDATE_RADIO_VALUE:
            let __ruleAttributeRadio = [...state.__ruleAttribute];
            let __IgnoreVarsRadio = [...state.ignoreVars];
            let __IgnoreVarsListRadio = { ...state.ignoreVarsList };
            let ignoreVarsTemp = [];
            // console.log([...state.ignoreVars]);
            if (!payload.disableIgnoreVars) {
                for (const [key, value] of Object.entries(
                    __IgnoreVarsListRadio[payload.parentId]
                )) {
                    // console.log(key, value);
                    if (parseInt(key) === parseInt(payload.childId)) {
                        // 13
                        value.forEach((v) => {
                            let __i = __IgnoreVarsRadio.indexOf(v);
                            if (__i !== -1) {
                                __IgnoreVarsRadio.splice(__i, 1);
                            }
                            if (
                                __IgnoreVarsListRadio[v] !== undefined &&
                                ignoreVarsTemp.indexOf(v) === -1
                            ) {
                                for (const [__key, __value] of Object.entries(
                                    __IgnoreVarsListRadio[v]
                                )) {
                                    // console.log(__ruleAttributeRadio[v-1], "attr");
                                    let selectedID =
                                        __ruleAttributeRadio[v - 1].data[0].id;
                                    if (Number(__key) === Number(selectedID)) {
                                        // console.log(selectedID, __value, 'Selected ID');
                                        __value.forEach((_v) => {
                                            let _i =
                                                __IgnoreVarsRadio.indexOf(_v);
                                            if (_i !== -1) {
                                                __IgnoreVarsRadio.splice(_i, 1);
                                            }
                                        });
                                    } else {
                                        ignoreVarsTemp =
                                            ignoreVarsTemp.concat(__value);
                                        // console.log(__key,__value, 'Other ID');
                                        __value.forEach((_v) => {
                                            __IgnoreVarsRadio.push(_v);
                                        });
                                    }
                                }
                            }
                        });
                    } else {
                        value.forEach((v) => {
                            if (__IgnoreVarsRadio.indexOf(v) === -1) {
                                __IgnoreVarsRadio.push(v);
                            }
                        });
                    }
                }
            }
            let __tempRadioObj = __ruleAttributeRadio[payload.parentId - 1];
            // console.log(
            // 	"UPDATE_RADIO_VALUE",
            // 	__tempRadioObj,
            // 	__IgnoreVarsRadio,
            // 	payload
            // );
            let __dataRadio = {
                id: payload.childId,
                var_name: payload.varName,
                allow_any: payload.allow_any,
                display_value_html: payload.display_value_html,
            };
            __tempRadioObj.data = [__dataRadio];
            __tempRadioObj.error = false;
            return {
                ...state,
                __ruleAttribute: __ruleAttributeRadio,
                ignoreVars: __IgnoreVarsRadio,
            };

        case UPDATE_CHECKBOX_VALUE:
            let __ruleAttributeCheckbox = [...state.__ruleAttribute];
            let __tempCheckboxObj =
                __ruleAttributeCheckbox[payload.parentId - 1];
            // console.log("UPDATE_CHECKBOX_VALUE", __tempCheckboxObj, payload);
            let __dataCheckbox = [];
            payload.childArray.forEach((v, i) => {
                __dataCheckbox.push({
                    id: v,
                    var_name: payload.varNameArray[i],
                    allow_any: payload.allow_anyArray[i],
                    display_value_html: payload.display_value_htmlArray[i],
                });
            });
            __tempCheckboxObj.data = __dataCheckbox;
            __tempCheckboxObj.error = false;
            return {
                ...state,
                __ruleAttribute: __ruleAttributeCheckbox,
            };

        case UPDATE_FOURTHSTEP_CHECKBOX:
            let __ruleAttributeFourthCheck = [...state.approvals_verification];
            __ruleAttributeFourthCheck[payload.varId - 1].data = [
                payload.isChecked,
            ];
            if (payload.isChecked) {
                return {
                    ...state,
                    approvals_verification: __ruleAttributeFourthCheck,
                };
            }
            if (payload.varId === 26) {
                return {
                    ...state,
                    approvals_verification: __ruleAttributeFourthCheck,
                };
            }
            if (payload.varId === 25) {
                return {
                    ...state,
                    approvals_verification: __ruleAttributeFourthCheck,
                    approval_attributes: [
                        {
                            approval_1: [],
                            display: "Tier #1",
                            tier: 1,
                            spc_email: [],
                        },
                    ],
                };
            }
            return { ...state };

        case UPDATE_INPUT_VALUE:
            let __ruleAttributeInput = [...state.__ruleAttribute];
            let __tempInputObj = __ruleAttributeInput[payload.id - 1];
            // console.log("UPDATE_INPUT_VALUE", __tempInputObj, payload);
            let __dataInput = [];
            if (payload.data) __dataInput.push(payload.data);
            __tempInputObj.data = __dataInput;
            __tempInputObj.error = payload.isError;
            __tempInputObj.error_text = payload.error_text;
            return {
                ...state,
                __ruleAttribute: __ruleAttributeInput,
            };

        case UPDATE_CLICK_VALUE:
            let __ruleAttributeClick = [...state.__ruleAttribute];
            let __tempInputClick = __ruleAttributeClick[payload.id - 1];
            // console.log("UPDATE_CLICK_VALUE", __tempInputClick, payload);
            __tempInputClick.data = [payload.isOpen];
            return {
                ...state,
                __ruleAttribute: __ruleAttributeClick,
            };

        case ADD_CLONE_VALUE:
            let __ruleAttributeCloneAdd = [...state.__ruleAttribute];
            let __cloneArray1 = [...state.cloneArray];
            let __tempCloneAdd = __ruleAttributeCloneAdd[payload.id - 1];
            // console.log("ADD_CLONE_VALUE", __tempCloneAdd, payload);
            __tempCloneAdd.data.push({ clone_id: payload.data }); // Add new clone id into data
            __cloneArray1.push(payload.data); // Add to cloneArray
            __tempCloneAdd.verify_dependent_varIds.forEach((v) => {
                // console.log(__ruleAttributeCloneAdd[v - 1]);
                __ruleAttributeCloneAdd[v - 1].data.push({
                    clone_id: payload.data,
                    value: null,
                    flight_details: {},
                    error: false,
                    error_text: "No Routes Selected",
                });
                __ruleAttributeCloneAdd[v - 1].custom = true;
                __ruleAttributeCloneAdd[v - 1].is_dep_var = true;
            });
            return {
                ...state,
                __ruleAttribute: __ruleAttributeCloneAdd,
                cloneArray: __cloneArray1,
            };

        case REMOVE_CLONE_VALUE:
            let __ruleAttributeCloneRemove = [...state.__ruleAttribute];
            let __cloneArray2 = [...state.cloneArray];
            let __tempCloneRemove = __ruleAttributeCloneRemove[payload.id - 1];
            // console.log("REMOVE_CLONE_VALUE", __tempCloneRemove, payload);
            let __temp = [];
            __tempCloneRemove.data?.forEach((__d, __i) => {
                if (__d.clone_id !== payload.data) __temp.push(__d);
            });
            __tempCloneRemove.data = __temp;
            let index = __cloneArray2.indexOf(parseInt(payload.data));
            __cloneArray2.splice(index, 1);
            __tempCloneRemove.verify_dependent_varIds.forEach((v) => {
                let __temp2 = [];
                __ruleAttributeCloneRemove[v - 1].data.forEach((__d) => {
                    if (__d.clone_id !== payload.data) __temp2.push(__d);
                });
                __ruleAttributeCloneRemove[v - 1].data = __temp2;
            });

            return {
                ...state,
                __ruleAttribute: __ruleAttributeCloneRemove,
                cloneArray: __cloneArray2,
            };

        case UPDATE_CLONE_CHILD_VALUE:
            let __ruleAttributeClone2 = [...state.__ruleAttribute];
            payload.childArray?.forEach((c) => {
                let __tempClone = __ruleAttributeClone2[c - 1];
                let __dataObj = [];
                __tempClone.data?.forEach((v) => {
                    if (v.clone_id !== payload.flightIndex) __dataObj.push(v);
                });
                __tempClone.data = __dataObj;
            });
            // console.log(
            // 	"UPDATE_CLONE_CHILD_VALUE",
            // 	__ruleAttributeClone2,
            // 	payload
            // );
            return {
                ...state,
                __ruleAttribute: __ruleAttributeClone2,
            };

        case UPDATE_CLONE_TEXT_VALUE:
            let __ruleAttributeCloneText = [...state.__ruleAttribute];
            let __tempInputCloneText = __ruleAttributeCloneText[payload.id - 1];
            // console.log(
            // 	"UPDATE_CLONE_TEXT_VALUE",
            // 	__tempInputCloneText,
            // 	payload.data
            // );
            let foundFlag = 0;
            __tempInputCloneText.data?.forEach((e) => {
                if (e.clone_id === payload.clone_id) {
                    e.value = payload.data ? payload.data.airport_name : "";
                    e.flight_details = payload.data;
                    foundFlag = 1;
                }
            });
            if (!foundFlag) {
                __tempInputCloneText.data.push({
                    clone_id: payload.clone_id,
                    value: payload.data ? payload.data.airport_name : "",
                    flight_details: payload.data,
                });
            }
            __tempInputCloneText.custom = true;
            __tempInputCloneText.is_dep_var = true;
            return {
                ...state,
                __ruleAttribute: __ruleAttributeCloneText,
            };

        case UPDATE_HIDDEN_VALUE:
            let __ruleAttributeHidden = [...state.hiddenVars];
            // console.log(
            // 	"UPDATE_UPDATE_HIDDEN_VALUECLONE_VALUE",
            // 	__ruleAttributeHidden,
            // 	payload.data
            // );
            return {
                ...state,
                hiddenVars: [...payload.data],
            };

        case UPDATE_MODES_ACTIVE_VARS:
            let __modesActiveVars = { ...state.modesActiveVars };
            // console.log(
            // 	"UPDATE_MODES_ACTIVE_VARS",
            // 	__modesActiveVars,
            // 	payload.data
            // );
            for (const [key, value] of Object.entries(__modesActiveVars)) {
                value.active =
                    payload.data.indexOf(parseInt(key)) === -1 ? false : true;
            }
            return {
                ...state,
                modesActiveVars: __modesActiveVars,
            };
        case UPDATE_RULE_ATTRIBUTES:
            // console.log("UPDATE_RULE_ATTRIBUTES", payload);
            return {
                ...state,
                __ruleAttribute: payload,
            };
        case UPDATE_ERROR:
            let __ruleAttributeError = [...state.__ruleAttribute];
            let __tempInputError = __ruleAttributeError[payload.parentId - 1];
            // console.log("UPDATE_ERROR", payload, __tempInputError);
            __tempInputError.error = payload.error;
            __tempInputError.error_text = payload.error_text;
            return {
                ...state,
                __ruleAttribute: __ruleAttributeError,
            };

        case ADD_APPROVER:
        case REMOVE_APPROVER:
        case SET_APPROVERS:
            return {
                ...state,
                approval_attributes: payload,
            };

        case FILL_INPUT_FIELD_TO_UPDATE_RULE:
            // console.log("ruleByidReducer", payload);
            return {
                ...state,
                __ruleAttribute: payload.__updateRuleAtrr,
                // __updateRuleAtrr
                // __ruleAttribute: payload.json.rule_attributes,
                approval_attributes: payload.ruleObj.json.approval_attributes,
                ignoreVars: payload.__ignoreVars,
                modesActiveVars: payload.__modesActiveVars,
                hiddenVars: payload.__hiddenVars,
                approvals_verification: payload.__approvals_verification,
                cloneArray: payload.__cloneArray,
            };

        case SET_UPDATE_RULE:
            return {
                ...state,
                __ruleAttribute: state.defaultRuleAttribute,
                approval_attributes: initialState.__approval_attributes,
            };

        case RESET_STATE:
            return {
                ...state,
                inputVars: [],
                __ruleAttribute: [],
                approval_attributes: [
                    {
                        approval_1: [],
                        display: "Approval #1",
                        tier: 1,
                        spc_email: [],
                        hod: [],
                        manager: [],
                    },
                ],
            };
        case UPDATE_COMPANY_DEFINED_FIELDS:
            let __ruleAttributeCDF = [...state.__ruleAttribute];
            let __tempInputCDF = __ruleAttributeCDF[payload.id - 1];
            __tempInputCDF.data = payload.data ? [payload.data] : [];
            __tempInputCDF.error = payload.error;
            __tempInputCDF.error_text = payload.error_text;
            return {
                ...state,
                __ruleAttribute: __ruleAttributeCDF,
            };

        case API_START:
        case API_END:
        case FETCH_INPUT_VARS:
        case FETCH_DEPT:
        case FETCH_GRADE:
        case FETCH_APPROVAL_LABEL:
        case FETCHING_AIRPORTS:
        case CREATE_APPROVAL_RULE:
        case UPDATE_RULE:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
}
