import React from "react";

const UserIcon = (props) => {
    return (
        <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
			{...props}
        >
            <rect
                x="0.585938"
                width="18.9619"
                height="18"
                fill="url(#pattern0)"
            />
            <defs>
                <pattern
                    id="pattern0"
                    patternContentUnits="objectBoundingBox"
                    width="1"
                    height="1"
                >
                    <use
                        xlinkHref="#image0"
                        transform="translate(0 -0.0267204) scale(0.0555556 0.0585245)"
                    />
                </pattern>
                <image
                    id="image0"
                    width="18"
                    height="18"
                    xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJrSURBVHgBjVNLbhNBEK3qtlnAAkcEUFZMlNjKDnOC2Cdglgji3wlITmByAswJGGM+2cU5gScniJdIceRhhURs2Qo7z3QXVWMPmLERedJo+lv96tUrhDW4PtgtIWKNvxIRObKGCH0g6Fui9sOPAz99B5cnk/qeY4x5D0SglfKmN7Oz7W4wlb2h6+Tu3c26SlENFTpKheUNLwhWAk3q+aK1eBqF5ujxl6uurI0q+Tr/9mWs0fobH67aMv7xMl9HBa/JQuPR58v+70DCxFrbm81m5a2T+Sujar4FhE80Go//UwMoQWGzc9mQ//cXe042a3paLzEbVwvD0audWsJudLDjjiqF07QOzLAn+i1rKWsyVjIha4PNT3Pac5r4FKzpwiraapGqIBFdYijF1WFxvfQNRJVbWSO4n17jqh4jUk3x80WdNWfLmxbgHBQcTuqOk6zxOAcKD2dR1F4+m8lELLYqIedILCCmXxpX8m/5ERcss0XIASqXC+JxOserZwtDBWtwXS284dxcMtaf84cp69hngx7Ge2uQYWEDoc0ljI0nVUCy/Zuf0bPEjAkWJW+Oq/mLhSHjfXb7lBmRb0L9PA7C3kEi/0FncJQOItg6+RrEPiLwrck2Y/ZxO0EfF33V1Fo3uD16fHAb/gPJwJg7F0S2IVVXQJ5KvCA9xuVtwS0gKXH6nhAggNJGZ3Aeix2GWmxfFGPCLUGI3+ROGIZlmaskd2ugzC3fmlT/tMq/IC0kbORO0pt/+WdeFcu9QwGQfaczxk8qM9cl6/JQOgHCKGokQVYCJZhUdvel21mzIrHzFwcDTt3XCj3RJH3nFzYyQSWvZ4vbAAAAAElFTkSuQmCC"
                />
            </defs>
        </svg>
    );
};

export default UserIcon;
