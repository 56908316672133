
//Family
export const fontPrimary= `"Roboto", sans-serif`;
export const fontSecondary= `"Inter", sans-serif`;

//Weights
export const textLight= "300";
export const textNormal= "400";
export const textSemibold= "500";
export const textSemiboldExtra= "600";
export const textBold= "700";
export const textBlack= "900";

//sizes
export const textxs = "0.75rem";
export const textsm = "0.875rem";
export const textmd = "1rem";
export const textlg = "1.125rem";
export const textxl = "1.25rem";
export const text2xl = "1.5rem";
export const text3xl = "1.875rem";
export const text4xl = "2.25rem";
export const text5xl = "3rem";
export const text6xl = "4rem";

/* -------------- Colors -------------*/

//Theme
export const themeMain= "#e95d25";
export const themeMainlight= "#dd6b20";

export const white= "#ffffff";
export const black= "#000000";

// gray
export const gray100= "#f7fafc";
export const gray200= "#edf2f7";
export const gray300= "#e2e8f0";
export const gray400= "#cbd5e0";
export const gray500= "#a0aec0";
export const gray600= "#718096";
export const gray700= "#4a5568";
export const gray800= "#2d3748";
export const gray900= "#1a202c";

// orange
export const orange100 = "#FFFAF0";
export const orange200 = "#FEEBC8";
export const orange300 = "#FBD38D";
export const orange400 = "#F6AD55";
export const orange500 = "#ED8936";
export const orange600 = "#DD6B20";
export const orange700 = "#EB5C24";
export const orange800 = "#9C4221";
export const orange900 = "#7B341E";

// blue
export const blue100 = "#EBF8FF";
export const blue200 = "#BEE3F8";
export const blue300 = "#90CDF4";
export const blue400 = "#63B3ED";
export const blue500 = "#4299E1";
export const blue600 = "#3182CE";
export const blue700 = "#2B6CB0";
export const blue800 = "#2C5282";
export const blue900 = "#2A4365";

//red
export const red50 = "#ffebee";
export const red100 = "#ffcdd2";
export const red200 = "#ef9a9a";
export const red300 = "#e57373";
export const red400 = "#ef5350";
export const red500 = "#f44336";
export const red600 = "#e53935";
export const red700 = "#d32f2f";
export const red800 = "#c62828";
export const red900 = "#b71c1c";
export const redA100 = "#ff8a80";
export const redA200 = "#ff5252";
export const redA300 = "#ff1744";
export const redA400 = "#d50000";
/* -------------- Spacers -------------*/

export const spacer0 = "0.2rem";
export const spacer1 = "0.25rem";
export const spacer2 = "0.5rem";
export const spacer3 = "0.75rem";
export const spacer4 = "1rem";
export const spacer5 = "1.25rem" 
export const spacer6 = "1.5rem";
export const spacer7 = "1.75rem";
export const spacer8 = "2rem";
export const spacer10 = "2.5rem";
export const spacer12 = "3rem";
export const spacer16 = "4rem";
export const spacer20 = "5rem";
export const spacer24 = "6rem";
export const spacer32 = "8rem";
export const spacer40 = "10rem";
export const spacer48 = "12rem";
export const spacer56 = "14rem";
export const spacer64 = "16rem";

/* -------------- Shadows -------------*/

export const boxShadow1= "0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)";
export const buttonBoxShadow= "0px 1px 2px rgba(0, 0, 0, 0.07)";