export const INPUT_VALUE = "INPUT_VALUE";

export const SET_REWARDS = "SET_REWARDS";

export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";

export const SET_COUNTRY = "SET_COUNTRY";

export const SET_CURRENCY = "SET_CURRENCY";

export const SET_PRODUCT_TYPE = "SET_PRODUCT_TYPE";

export const SUBMIT_FORM = "SUBMIT_FORM";

export const SET_REQUEST_TOKEN = "SET_REQUEST_TOKEN";

export const SET_AUTHENTICATION = "SET_AUTHENTICATION";

export const UPDATE_ERRORS = "UPDATE_ERRORS";

export const UPDATE_ERROR_MSG = "UPDATE_ERROR_MSG";
