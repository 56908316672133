import { createTheme } from "@material-ui/core";
import { grey, orange } from "@material-ui/core/colors";
import { primaryMain } from "./__variable";

const __globals = createTheme({
	palette: {
		primary: {
			main: primaryMain,
			light: orange[600],
		},
		secondary: {
			light: grey[600],
			main: grey[700],
			dark: grey[800],
		},
	},
	typography: {
		fontFamily: ["'Roboto'", "'Inter'", "sans-serif"].join(","),
	},
	props: {
		MuiButtonBase: {
			disableRipple: true,
		},
	},
});
export default __globals;
