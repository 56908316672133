import axios from "axios";
import LocalStorageService from "../../services/LocalStorage";
import toastService from "../../services/ToastService";
import { API } from "../constants/common/Api.Types";
import { accessDenied, apiError, apiStart, apiEnd } from "./Middleware.actions";
import { validateAccessToken } from "../../utils/validateAccessToken";
const moment = require("moment");

const apiMiddleware =
    ({ dispatch }) =>
    (next) =>
    async (action) => {
        if (action) {
            next(action);

            if (action.type !== API) return;

            const {
                url,
                method,
                data,
                accessToken,
                onSuccess,
                onFailure,
                label,
                headersOverride,
                tempHeaders,
            } = action.payload;

            // axios default configs
            axios.defaults.baseURL = process.env.REACT_APP_BASEURL || "";
            axios.defaults.headers.common["Content-Type"] = "application/json";
            // axios.defaults.headers.common['access-token'] = accessToken;
            // axios.defaults.headers.common['Authorization'] = `Bearer ${LocalStorageService.getAccessToken()}` ;
            let currTimeUnix = moment().unix(); // secs

            let acc_token = await validateAccessToken().then((r) => r);
            // console.log('indexMiddleware', currTimeUnix, acc_token)

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${acc_token}`;
            // axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

            if (headersOverride !== null) {
                for (let key in headersOverride) {
                    console.log(`${key}: ${headersOverride[key]}`);
                    // adding new header for search api
                    // axios.defaults.headers.post[key] = headersOverride[key];
                }
            }

            if (label) {
                dispatch(apiStart(label));
            }

            let axiosConfig = {
                url,
                method,
                headersOverride,
                data,
            };

            if (tempHeaders) {
                axiosConfig.headers = tempHeaders;
            }

            await axios(axiosConfig)
                .then(({ data }) => {
                    dispatch(onSuccess(data));
                })
                .catch((error) => {
                    console.log("Error", url, error);
                    // dispatch(apiError(error.response.data));
                    // dispatch(onFailure(error.response.data));

                    if (
                        error.response &&
                        (error.response.status === 403 ||
                            error.response.status === 401)
                    ) {
                        toastService.notify(error.response.statusText);
                        // alert(error.response.statusText);
                        LocalStorageService.removeAccessToken();
                        localStorage.removeItem("token");
                        window.location.href = "/";
                        dispatch(accessDenied(window.location.pathname));
                    }
                    if (
                        error.response &&
                        (error.response.status === 500 ||
                            error.response.status === 400 ||
                            error.response.status === 404)
                    ) {
                        dispatch(onFailure(error.response.data)); // enabling it to detect 500 errors
                    }
                })
                .finally(() => {
                    if (label) {
                        dispatch(apiEnd(label));
                    }
                });
        }
    };

export default apiMiddleware;
