export const FETCH_INPUT_VARS = "FETCH_INPUT_VARS";
export const SET_INPUT_VARS = "SET_INPUT_VARS";

export const FETCH_DEPT = "FETCH_DEPT";
export const SET_DEPT = "SET_DEPT";

export const FETCH_GRADE = "FETCH_GRADE";
export const SET_GRADE = "SET_GRADE";

export const FETCH_APPROVAL_LABEL = "FETCH_APPROVAL_LABEL";
export const SET_APPROVAL_LABEL = "SET_APPROVAL_LABEL";

export const CREATE_APPROVAL_RULE = "CREATE_APPROVAL_RULE";
export const SET_CREATE_APPROVAL_RULE = "SET_CREATE_APPROVAL_RULE";

export const UPDATE_DROPDOWN_VALUE = "UPDATE_DROPDOWN_VALUE";

export const UPDATE_OPERATOR_DROPDOWN_VALUE = "UPDATE_OPERATOR_DROPDOWN_VALUE";

export const UPDATE_MULTISELECT_VALUE = "UPDATE_MULTISELECT_VALUE";

export const UPDATE_RADIO_VALUE = "UPDATE_RADIO_VALUE";

export const UPDATE_CHECKBOX_VALUE = 'UPDATE_CHECKBOX_VALUE';

export const UPDATE_INPUT_VALUE = "UPDATE_INPUT_VALUE";

export const UPDATE_CLICK_VALUE = "UPDATE_CLICK_VALUE";

export const ADD_CLONE_VALUE = "ADD_CLONE_VALUE";

export const REMOVE_CLONE_VALUE = "REMOVE_CLONE_VALUE";

export const UPDATE_CLONE_TEXT_VALUE = "UPDATE_CLONE_TEXT_VALUE";

export const UPDATE_HIDDEN_VALUE = "UPDATE_HIDDEN_VALUE";

export const UPDATE_CLONE_CHILD_VALUE = "UPDATE_CLONE_CHILD_VALUE";

export const UPDATE_MODES_ACTIVE_VARS = "UPDATE_MODES_ACTIVE_VARS";

export const UPDATE_RULE_ATTRIBUTES = "UPDATE_RULE_ATTRIBUTES";

export const UPDATE_ERROR = "UPDATE_ERROR";

export const ADD_APPROVER = 'ADD_APPROVER';

export const REMOVE_APPROVER = 'REMOVE_APPROVER';

export const SET_APPROVERS = 'SET_APPROVERS';

export const FILL_INPUT_FIELD_TO_UPDATE_RULE = 'FILL_INPUT_FIELD_TO_UPDATE_RULE';

export const UPDATE_RULE = 'UPDATE_RULE';
export const SET_UPDATE_RULE = 'SET_UPDATE_RULE';

export const FETCHING_AIRPORTS = 'FETCHING_AIRPORTS';
export const SET_AIRPORTS = 'SET_AIRPORTS';

export const UPDATE_FOURTHSTEP_CHECKBOX = 'UPDATE_FOURTHSTEP_CHECKBOX';

export const UPDATE_COMPANY_DEFINED_FIELDS = 'UPDATE_COMPANY_DEFINED_FIELDS';

export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';