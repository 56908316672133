import {
	FLIGHT_ROUTE_STATE_UPDATE,
	FLIGHT_DURATION_STATE_UPDATE,
	FLIGHT_SETUP_STATE_UPDATE,
	FLIGHT_PREFERENCES_STATE_UPDATE,
	FLIGHT_RULE_SUCCESS,
	RESET_FLIGHT_STATE,
	FLIGHT_EMPLOYEE_STATE_UPDATE,
	FLIGHT_POLICY_NAME_UPDATE,
	FLIGHT_RULE_FETCH_SUCCESS,
	FLIGHT_ERROR_VALIDATION,
	INIT_FLIGHT_STATE,
    FLIGHT_POLICY_RULE_SUBMIT,
    FLIGHT_POLICY_RULE_FETCH,
} from "../../../../constants/IL_Admin/PolicyEditor/Single/FlightPolicySingle.types";
import createReducer from "../../../../../utils/createReducer";
import {
	API_START,
	API_END,
	API_ERROR,
} from "../../../../constants/common/Api.Types";

const initialState = {
	isLoading: null,
	isSubmitting: false,
	redirectToList: null,
	client: null,
	rule: {},
	ruleName: "",
	ruleNo: null,
	formStep: {
		control: {
			employee_level: [],
			duration: [],
			route: {},
		},
		setup: {},
		preferences: [],
	},
	submitRule: {
		status: null,
		errorMsg: "",
	},
	errorMsgs: {
		ruleName: "",
		control: {
			employee_level: [],
			duration: [],
			route: {
				custom_domestic: "",
				custom_route: [],
			},
		},
		setup: {
			fixed_limit: "",
			dynamic_limit: {
				il_fare_flexibility: "",
				cl_fare_flexibility: "",
				budget: "",
			},
		},
		preferences: [],
	},
	errors: [],
	init: false,
};

export const reducerObj = {
	[API_START]: (prevState, payload) => {
		if (payload === FLIGHT_POLICY_RULE_SUBMIT) {
			return {
				isSubmitting: true,
				submitRule: {
					status: null,
					errorMsg: "",
				},
			};
		}
		if (payload === FLIGHT_POLICY_RULE_FETCH) {
			return {
				isLoading: true,
			};
		}
	},
	[API_END]: (prevState, payload) => {
		if (payload === FLIGHT_POLICY_RULE_SUBMIT) {
			return {
				isSubmitting: false,
			};
		}
	},
	[API_ERROR]: (prevState, payload) => {
		if (payload === FLIGHT_POLICY_RULE_SUBMIT) {
			return {
				isSubmitting: false,
				submitRule: {
					status: 400,
					errorMsg: payload,
				},
			};
		}
	},
	[FLIGHT_RULE_SUCCESS]: (prevState, payload) => {
		return {
			redirectToList: true,
			submitRule: {
				status: 200,
			},
			ruleNo: parseInt(payload.result)
		};
	},
	[FLIGHT_EMPLOYEE_STATE_UPDATE]: (prevState, payload) => {
		const _temp = prevState.formStep;
		_temp.control.employee_level = payload;
		return {
			...prevState,
			formStep: {
				..._temp,
			},
		};
	},
	[FLIGHT_ROUTE_STATE_UPDATE]: (prevState, payload) => {
		const _temp = prevState.formStep;
		_temp.control.route = payload;
		return {
			...prevState,
			formStep: {
				..._temp,
			},
		};
	},
	[FLIGHT_DURATION_STATE_UPDATE]: (prevState, payload) => {
		const _temp = prevState.formStep;
		_temp.control.duration = payload;
		return {
			...prevState,
			formStep: {
				..._temp,
			},
		};
	},
	[FLIGHT_SETUP_STATE_UPDATE]: (prevState, payload) => {
		const _temp = prevState.formStep;
		_temp.setup = payload;
		return {
			...prevState,
			formStep: {
				..._temp,
			},
		};
	},
	[FLIGHT_PREFERENCES_STATE_UPDATE]: (prevState, payload) => {
		const _temp = prevState.formStep;
		_temp.preferences = payload;
		return {
			...prevState,
			formStep: {
				..._temp,
			},
		};
	},
	[FLIGHT_POLICY_NAME_UPDATE]: (prevState, payload) => {
		return {
			...prevState,
			ruleName: payload,
		};
	},
	[FLIGHT_RULE_FETCH_SUCCESS]: (prevState, payload) => {
		return {
			...prevState,
			rule: payload,
			formStep: { ...payload.policy },
			ruleName: payload.description,
			client: payload.client_id,
			isLoading: false,
		};
	},
	[FLIGHT_ERROR_VALIDATION]: (prevState, payload) => {
		return {
			errors: payload.errors,
			errorMsgs: payload.errorMsgs,
		};
	},
	[RESET_FLIGHT_STATE]: () => {
		return {
			isLoading: null,
			isSubmitting: false,
			redirectToList: null,
			client: null,
			rule: {},
			ruleName: "",
			ruleNo: null,
			formStep: {
				control: {
					employee_level: [],
					duration: [],
					route: {},
				},
				setup: {},
				preferences: [],
			},
			submitRule: {
				status: null,
				errorMsg: "",
			},
			errorMsgs: {
				ruleName: "",
				control: {
					employee_level: [],
					duration: [],
					route: {
						custom_domestic: "",
						custom_route: [],
					},
				},
				setup: {
					fixed_limit: "",
					dynamic_limit: {
						il_fare_flexibility: "",
						cl_fare_flexibility: "",
						budget: "",
					},
				},
				preferences: [],
			},
			errors: [],
			init: false,
		};
	},
	[INIT_FLIGHT_STATE]: () => {
		return {
			isLoading: null,
			isSubmitting: false,
			redirectToList: null,
			client: null,
			rule: {},
			ruleName: "",
			ruleNo: null,
			formStep: {
				control: {
					employee_level: [],
					duration: [],
					route: {},
				},
				setup: {},
				preferences: [],
			},
			submitRule: {
				status: null,
				errorMsg: "",
			},
			errorMsgs: {
				ruleName: "",
				control: {
					employee_level: [],
					duration: [],
					route: {
						custom_domestic: "",
						custom_route: [],
					},
				},
				setup: {
					fixed_limit: "",
					dynamic_limit: {
						il_fare_flexibility: "",
						cl_fare_flexibility: "",
						budget: "",
					},
				},
				preferences: [],
			},
			errors: [],
			init: true,
		};
	},
};

const FlightPolicySingleReducer = createReducer(initialState, reducerObj);

export default FlightPolicySingleReducer;
