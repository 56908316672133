import {
	USER_INTEGRATIONS_SUCCESS,USER_INTEGRATIONS_ERROR
} from "../../constants/Integrations/Integrations.type";
import createReducer from "../../../utils/createReducer";
const initialState = {
	isLoading: true,
	integrateService: [],
	errorStatus: false,
	errorMsgs: '',
};
const UserIntegrations =  function reducerObj(state = initialState, action = {}) {
	const { type, payload } = action;
	switch (type) {
		case USER_INTEGRATIONS_SUCCESS:
			return {
				...state,
				isLoading: false,
                integrateService:payload
			};
        case USER_INTEGRATIONS_ERROR:
            return {
                ...state,
                isLoading: false,
                errorStatus: true,
	            errorMsgs: payload,
            };

		default:
			return state;
	}
}

export default UserIntegrations;
