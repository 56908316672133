import React from "react";
import { Route, Redirect } from "react-router-dom";
import LocalStorageService from "../../services/LocalStorage";
import { routeUtils } from "../../utils/routesUtils";
import Page404 from "../../components/Page404/Page404";

function PublicRoute(props) {
    if (LocalStorageService.getAccessToken()) {
        let app_route = routeUtils(localStorage.getItem("app"));
        return <Redirect to={app_route} />;
    }
    if (!LocalStorageService.getAccessToken()) {
        if (!props.isLoggingIn)
            return <Page404 isLoggingIn={props.isLoggingIn} />;
        else {
            return <Route {...props} />;
        }
    }
}

export default PublicRoute;
