// creating new reducer
import createReducer from "../../../../../utils/createReducer";

// importing actions types
import {
    FETCH_RULE_LISTS,
    SET_RULE_LISTS,
    FETCH_RULE_BY_ID,
    SET_RULE_BY_ID,
    DELETE_RULE,
    SET_DELETE_RULE,
    REORDER_RULE,
    SET_REORDER_RULE,
    SET_APPROVAL_LABEL_LIST,
    SET_SESSION_VARIABLE,
} from "../../../../constants/IL_Admin/Approvals/RuleList/RuleList.types";
import {
    API_START,
    API_END,
    RESET_STATE,
} from "../../../../constants/common/Api.Types";

import { services } from "@itilite/utils";

const { IUILocalStorageService } = services;

const roleCanAccess = {
    travel_itilite_admin: true,
    travel_admin: true,
    expense_itilite_admin: true,
    expense_admin: true,
};

// initalState
export const initialState = {
    rules: null,
    isLoading: false,
    isShowTable: false,
    ruleById: [],
    deleteRule: {},
    approvalLabels: {},
};

// rulelist reducer
export const reducerObj = {
    [SET_RULE_LISTS]: (prevState, payload) => ({
        rules: payload,
    }),

    [SET_RULE_BY_ID]: (prevState, payload) => ({
        ruleById: payload,
    }),

    [SET_DELETE_RULE]: (prevState, payload) => ({
        ruleById: { ...payload },
    }),

    [SET_REORDER_RULE]: (prevState, payload) => ({
        rules: payload,
    }),

    [RESET_STATE]: (prevState, payload) => ({
        ruleById: [],
    }),

    // ------------- Api end and start loading state ----------
    [API_START]: (prevState, payload) => {
        if (payload === FETCH_RULE_LISTS || payload === REORDER_RULE) {
            return {
                isShowTable: true,
            };
        } else if (payload === FETCH_RULE_BY_ID || payload === DELETE_RULE) {
            return {
                isLoading: true,
            };
        }
    },
    [API_END]: (prevState, payload) => {
        if (payload === FETCH_RULE_LISTS || payload === REORDER_RULE) {
            return {
                isShowTable: false,
            };
        } else if (payload === FETCH_RULE_BY_ID || payload === DELETE_RULE) {
            return {
                isLoading: false,
            };
        }
    },
    [SET_APPROVAL_LABEL_LIST]: (prevState, payload) => ({
        approvalLabels: payload.result.approvers,
    }),
    [SET_SESSION_VARIABLE]: (prevState, payload) => {
        if (payload.status === true) {
            payload.applicable_role.every((element) => {
                if (roleCanAccess[element]) {
                    IUILocalStorageService.setCompanyId(payload.company);
                    IUILocalStorageService.setRole(element);
                    return false;
                }
                return true;
            });
        }
    },
};

const RuleListReducer = createReducer(initialState, reducerObj);

export default RuleListReducer;
