import React from "react";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

function SkeletonLoaderOnboarding() {
	return (
		<Box component='div' className='onboarding__wrapper'>
			<Box component='div'>
				<Skeleton variant='text' height='50px' />
			</Box>
			<Box className='onboarding--stepper__container' paddingTop={2}>
				<Skeleton variant='text' height='60px' width='300px' />
				<Skeleton variant='text' height='40px' width='200px' />
				<Box
					display='flex'
					flexWrap='wrap'
					component='div'
					style={{ margin: "30px 0 15px" }}
				>
					<Skeleton variant='text' height='80px' width='100%' />
					<Box
						display='flex'
						justifyContent='space-between'
						component='div'
						width='100%'
					>
						<Skeleton variant='text' height='80px' width='49%' />
						<Skeleton variant='text' height='80px' width='49%' />
					</Box>
					<Skeleton variant='text' height='80px' width='100%' />
					<Skeleton variant='text' height='80px' width='100%' />
					<Skeleton variant='text' height='20px' width='100%' />
					<Skeleton variant='text' height='20px' width='100%' />
					<Box
						display='block'
						component='div'
						width='100%'
						style={{ marginTop: 50 }}
					>
						<Skeleton variant='text' height='20px' width='100%' />
						<Skeleton variant='text' height='80px' width='100%' />
					</Box>
				</Box>
			</Box>
		</Box>
	);
}

export default SkeletonLoaderOnboarding;
