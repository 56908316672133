import { createStore, applyMiddleware, compose } from "redux";
import reducer from "./reducers";
import { createLogger } from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

// importing the apiMiddleware
import apiMiddleware from "./middleware";

const middlewares = [apiMiddleware, thunk];
// initialze the logger only in deveployment
// const logger = createLogger();
if (process.env.NODE_ENV === "development") {
  const logger = createLogger();

  // const { logger } = require('redux-logger');
  // middlewares.push(logger);
}

// initialize the redux with enhancer by chrome devtools (redux)
const composeEnhancers = composeWithDevTools() || compose;

// creating the store
const store = createStore(
  reducer,
  compose(applyMiddleware(...middlewares), composeEnhancers)
);

// exporting the store
export { store };
