import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import SidebarNav from "../components/SideBar/Sidebar";
import clsx from "clsx";
import Footer from "../components/Footer/Footer";
import Header from "../components/Navbar/Header";
import HeaderNonLogged from "../components/Navbar/HeaderNonLogged";

const useStyle = makeStyles({
    AppContent: {
        minHeight: `calc(100vh - 64px - 50px)`,
        borderLeft: `1px solid #dddddd`,
        width: "100%",
    },
    pageWithSidebar: {
        flex: "0.9 1",
        "& >.MuiBox-root:first-child": {
            minHeight: `calc(100vh - 64px - 50px)`,
        },
    },
    appWrapperPadding: {
        padding: "64px 0 0",
    },
});
export default function AppWrapper(props) {
    const { isSidebar = false } = props;
    const classes = useStyle();
    return (
        <>
            <Box
                component="div"
                display="flex"
                className={classes.appWrapperPadding}
            >
                {isSidebar && <SidebarNav />}

                <Box
                    component="div"
                    className={clsx(classes.AppContent, {
                        [classes.pageWithSidebar]: isSidebar,
                    })}
                >
                    {props.children}
                </Box>
            </Box>
        </>
    );
}
