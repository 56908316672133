export const INIT_FLIGHT_STATE = "INIT_FLIGHT_STATE";
export const RESET_FLIGHT_STATE = "RESET_FLIGHT_STATE";
export const COPY_INIT_FLIGHT_STATE = "COPY_INIT_FLIGHT_STATE";
export const FLIGHT_RULE_SUCCESS = "FLIGHT_RULE_SUCCESS";

export const FLIGHT_RULE_FETCH_SUCCESS = "FLIGHT_RULE_FETCH_SUCCESS";
export const FLIGHT_POLICY_RULE_SUBMIT = "FLIGHT_POLICY_RULE_SUBMIT";
export const FLIGHT_POLICY_RULE_FETCH = "FLIGHT_POLICY_RULE_FETCH";

export const FLIGHT_POLICY_NAME_UPDATE = "FLIGHT_POLICY_NAME_UPDATE";
export const FLIGHT_EMPLOYEE_STATE_UPDATE = "FLIGHT_EMPLOYEE_STATE_UPDATE";
export const FLIGHT_ROUTE_STATE_UPDATE = "FLIGHT_ROUTE_STATE_UPDATE";
export const FLIGHT_DURATION_STATE_UPDATE = "FLIGHT_DURATION_STATE_UPDATE";
export const FLIGHT_SETUP_STATE_UPDATE = "FLIGHT_SETUP_STATE_UPDATE";
export const FLIGHT_PREFERENCES_STATE_UPDATE =
	"FLIGHT_PREFERENCES_STATE_UPDATE";
export const FLIGHT_ERROR_VALIDATION = "FLIGHT_ERROR_VALIDATION";
