import {
	INPUT_VALUE,
	ENABLE_TERMS,
	USER_DETAILS,
	PASSWORD_SHOW_CHANGE,
	SET_STEP,
	UPDATE_ERRORS,
	UPDATE_ERROR_MSG,
	SET_REQ_TOKEN,
	SET_GEOLOCATION,
	UPDATE_COUNTRY_NAME,
	SET_COUNTRY_CODE_LIST,
} from "../../../constants/IL_Admin/Onboarding/UserDetails.types";

const initialState = {
	step: 0,
	isLoading: true,
	termsConditions: false,
	showPassword: false,
	userDetails: {
		first_name: "",
		last_name: "",
		email: "",
		password: "",
		phone_number: "",
		country: "",
		phone_country_code: "",
		is_travel: 0,
		is_expense: 0,
	},
	errors: [],
	errorMsgs: {},
	reqToken: "",
	geolocation: {},
	countryCodeList: {}
};
export default function reducerObj(state = initialState, action = {}) {
	const { type, payload } = action;
	switch (type) {
		case INPUT_VALUE:
			return {
				...state,
				userDetails: payload,
			};

		case USER_DETAILS:
			return {
				...state,
				userDetails: payload.userDetails,
			};
		case PASSWORD_SHOW_CHANGE:
			return {
				...state,
				showPassword: payload.showPassword,
			};

		case UPDATE_ERRORS:
			return {
				...state,
				errors: payload.errors,
			};

		case UPDATE_ERROR_MSG:
			return {
				...state,
				errorMsgs: payload.errorMsg,
			};

		case UPDATE_COUNTRY_NAME:
			let userDetails_CN = { ...state.userDetails };
			userDetails_CN["country"] = payload.value;

			return {
				...state,
				userDetails: userDetails_CN,
			};

		case SET_STEP:
			return {
				...state,
				step: payload.step,
			};

		case ENABLE_TERMS:
			return {
				...state,
				termsConditions: payload.termsConditions,
			};

		case SET_REQ_TOKEN:
			return {
				...state,
				requestToken: payload.token,
			};

		case SET_GEOLOCATION:
			let userDetails_GEO = { ...state.userDetails };
			userDetails_GEO["country"] = payload.country_code;
			userDetails_GEO["phone_country_code"] =
				payload.country_calling_code.replace("+", "");
			return {
				...state,
				userDetails: userDetails_GEO,
				geolocation: payload,
			};
		case SET_COUNTRY_CODE_LIST:
			return {
				...state,
				countryCodeList: payload,
				isLoading: false
			};

		default:
			return state;
	}
}

// const UserDetailsReducer = createReducer(initialState, userDetailsObj);

// export default UserDetailsReducer;
