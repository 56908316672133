/* eslint-disable react-hooks/exhaustive-deps */
import './GettingStartedWith.scss';
import { GettingStartedWith } from '@itilite/iui-getting-started'
import '@itilite/iui-getting-started/dist/index.css'

import SkeletonLoaderOnboarding from "../Onboarding/ProfileDetails/SkeleteonLoaderOnboarding";

function GettingStarted({ result, isLoading }) {

    return (
        Object.keys(result).length && !isLoading
            ? GettingStartedWith(result)
            : (
                <div className='getting-started-loader-container'>
                    <SkeletonLoaderOnboarding />
                </div>
            )
    )
}

export default GettingStarted
