import createReducer from "../../../../utils/createReducer";
import { API_END, API_START } from "../../../constants/common/Api.Types";
import {
    FETCH_TRIPS_LIST,
    SET_ALL_TRIPS_LIST,
    SET_TRIP_DETAILS,
    RESET_TRIP_DETAILS
} from "../../../constants/IL_Admin/AllTrips";

const initialState = {
    allTripsList: null,
    total: null,
    isLoading: true,
    tripDetailsLoading: true,
    tripDetails: null,
};

export const reducerObj = {
    [API_START]: (prevState, payload) => {
        if (payload === FETCH_TRIPS_LIST) {
            return {
                isLoading: true,
            };
        }
    },
    [API_END]: (prevState, payload) => {
        if (payload === FETCH_TRIPS_LIST) {
            return {
                isLoading: false,
            };
        }
    },
    [SET_ALL_TRIPS_LIST]: (prevState, payload) => {
        return {
            allTripsList: payload.results,
            total: payload.count,
            isDataFetching: false,
        };
    },
    [SET_TRIP_DETAILS]: (prevState, payload) => {
        return {
            tripDetails: payload.result,
        };
    },
    [RESET_TRIP_DETAILS]: (prevState, payload) => {
        return {
            tripDetails: null,
        };
    },
};

const AllTripsReducer = createReducer(initialState, reducerObj);
export default AllTripsReducer;
