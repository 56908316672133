import {
    SET_STAFF_DETAILS,
    SET_CARD_DETAILS,
    MAKE_CARD_DEFAULT,
    DEL_CARD_DETAILS,
    SHOW_CARDDETAILS_SKELETEN,
    HIDE_CARDDETAILS_SKELETEN,
} from "../../../constants/IL_Admin/PaymentMethods";

const initialState = {
    showSkeleten: true,
    paymentAccess: false,
    entityId: false,
    appType: false,
    vendorType: false,
    paymentCardType: false,
    cardDetails: false,
};

const CardDetailsReducer = function reducerObj(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_STAFF_DETAILS:
            return {
                ...state,
                paymentAccess: payload.payment_access,
                entityId: payload.entity_id,
                vendorType: payload.vendor_id,
                appType: 1,
                paymentCardType: 2,
            };
        case SET_CARD_DETAILS:
            return {
                ...state,
                cardDetails: payload,
                showSkeleten: false,
            };
        case MAKE_CARD_DEFAULT:
            const changeDefaultCard = state.cardDetails.map((card) => {
                if (card.id === payload) {
                    return {
                        ...card,
                        metadata: {
                            default_card: "1",
                        },
                    };
                } else {
                    return {
                        ...card,
                        metadata: {
                            default_card: "0",
                        },
                    };
                }
            });
            let idx=changeDefaultCard.findIndex(el=>el.metadata.default_card==1);
            let defaultcard=changeDefaultCard.splice(idx,1);
            if(defaultcard.length>0){
            changeDefaultCard.unshift(defaultcard[0]);
        };
            return {
                ...state,
                cardDetails: changeDefaultCard,
                showSkeleten: false,
            };
        case DEL_CARD_DETAILS:
            const delotherCards = state.cardDetails.filter(
                (card) => card.id !== payload
            );
            return {
                ...state,
                cardDetails: delotherCards,
                showSkeleten: false,
            };
        case SHOW_CARDDETAILS_SKELETEN:
            return {
                ...state,
                showSkeleten: true,
            };
        case HIDE_CARDDETAILS_SKELETEN:
            return {
                ...state,
                showSkeleten: false,
            };
        default:
            return state;
    }
};

export default CardDetailsReducer;
