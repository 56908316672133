import React from "react";
import "./Sidebar.scss";
import { Box, List } from "@material-ui/core";
import { roleBasedPermission } from "../../utils/roleBasedPermission";
import { useSelector } from "react-redux";
import LocalStorageService from "../../services/LocalStorage";
import MainMenu from "./MainMenu";
import SubMenu from "./SubMenu";
import { listItems } from "./MenuData";

export default function SidebarNav() {
    const { sidebarMenuPermissions } = useSelector(
        (state) => state.AuthReducer
    );
    const role = LocalStorageService.getRole();

    const getMenus = (listItem, index) => {
        if (listItem.isChildMenu) {
            /* get menues with child menu */
            return (
                <SubMenu
                    checkURLMatchByParts={checkURLMatchByParts}
                    listItem={listItem}
                    index={index}
                    key={index}
                />
            );
        } else {
            return (
                /* get menues without child menu */
                <MainMenu
                    checkURLMatchByParts={checkURLMatchByParts}
                    listItem={listItem}
                    index={index}
                    key={index}
                />
            );
        }
    };
    function checkURLMatchByParts(mainUrl, UrlToMatchList) {
        return UrlToMatchList.some((_u) => mainUrl.indexOf(_u) > -1);
    }
    return (
        <>
            <Box component="div" className="sidebar__container iui__scrollbar">
                <List
                    component="nav"
                    aria-label="Side navbar"
                    className="sidebar__wrapper"
                >
                    {listItems?.map((listItem, index) => {
                        if (
                            sidebarMenuPermissions.indexOf(
                                listItem.routeName
                            ) === -1
                        )
                            return false;

                        if (
                            roleBasedPermission[listItem.routeName].indexOf(
                                role
                            ) === -1
                        )
                            return null;
                        return getMenus(listItem, index);
                    })}
                </List>
            </Box>
        </>
    );
}
