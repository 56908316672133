import { FETCH_POLICY_LIST } from "../../../../constants/IL_Admin/PolicyEditor/List/CommonPolicyList.types";
import {
    SET_HOTEL_POLICY_LIST,
    GET_HOTEL_POLICY_RULE,
} from "../../../../constants/IL_Admin/PolicyEditor/List/HotelPolicyList.types";
import createReducer from "../../../../../utils/createReducer";
import { API_START, API_END } from "../../../../constants/common/Api.Types";
const initialState = {
    isLoading: false,
    client: null,
    rules: null,
    hotelPolicyRules: [],
};

export const reducerObj = {
    [API_START]: (prevState, payload) => {
        if (payload === FETCH_POLICY_LIST) {
            return {
                isLoading: true,
            };
        }
    },
    [API_END]: (prevState, payload) => {
        if (payload === FETCH_POLICY_LIST) {
            return {
                isLoading: false,
            };
        }
    },
    [SET_HOTEL_POLICY_LIST]: (prevState, payload) => {
        return {
            isLoading: false,
        };
    },
    [GET_HOTEL_POLICY_RULE]: (prevState, payload) => {
        return {
            isLoading: false,
            hotelPolicyRules: payload,
        };
    },
};

const HotelPolicyListReducer = createReducer(initialState, reducerObj);

export default HotelPolicyListReducer;
