import React, {Suspense} from 'react';

const SuspenseContainer = (props) => {
	return (
	<Suspense fallback={<div className="pageSkeleton"></div>}>
		{props.children}
	</Suspense>
	);
}

export default SuspenseContainer;