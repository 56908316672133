import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./utils/reportWebVitals";
import __globals from "./theming/__globals";
import __override from "./theming/__override";
import ThemeHOC from "./theming/ThemeHOC";

import { store } from './redux/store'; // importing the redux store
import { Provider } from 'react-redux';

import './utils/i18n/i18n';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ThemeHOC
				component={<App />}
				themeObj={{ ...__globals, overrides: __override }}
			/>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
