export const FETCH_RULE_LISTS = "FETCH_RULE_LISTS";
export const SET_RULE_LISTS = "SET_RULE_LISTS";

export const FETCH_RULE_BY_ID = "FETCH_RULE_BY_ID";
export const SET_RULE_BY_ID = "SET_RULE_BY_ID";

export const DELETE_RULE = "DELETE_RULE";
export const SET_DELETE_RULE = "SET_DELETE_RULE";

export const REORDER_RULE = "REORDER_RULE";
export const SET_REORDER_RULE = "SET_REORDER_RULE";
export const FETCH_APPROVAL_LABEL_LIST = "FETCH_APPROVAL_LABEL_LIST";
export const SET_APPROVAL_LABEL_LIST = "SET_APPROVAL_LABEL_LIST";

export const UPDATE_SESSION_VARIABLE = "UPDATE_SESSION_VARIABLE";
export const SET_SESSION_VARIABLE = "SET_SESSION_VARIABLE";
