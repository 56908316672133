import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();

const ToastService = (function () {
    function _notify(msg) {
        return toast.info(msg, {
            autoClose: true,
            className: "toast_notify_error",
        });
    }

    function _successNotify(message) {
        return toast.success(message, {
            autoClose: true,
            className: "toast_notify_success",
        });
    }

    function _errorNotify(message) {
        return toast.error(message, {
            autoClose: true,
            className: "toast_notify_error",
        });
    }

    return {
        notify: _notify,
        successNotication: _successNotify,
        error: _errorNotify,
    };
})();

export default ToastService;
