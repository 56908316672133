import React, { memo, useEffect } from "react";
import "./Page404.scss";
import LocalStorageService from "../../services/LocalStorage";
import { IUITypography } from "@itilite/iuistyles";
import FeedbackIcon from "@material-ui/icons/Feedback";

const Page404 = ({ isLoggingIn, isInitCheck }) => {
    useEffect(() => {
        if (!LocalStorageService.getAccessToken()) {
            if (!isLoggingIn) {
                setTimeout(() => {
                    window.location.href =
                        process.env.REACT_APP_ITILITE_BASE_URL +
                        "/staff-dashboard";
                }, [500]);
            }
        }
    }, []);

    if (LocalStorageService.getAccessToken())
        if (isInitCheck)
            return (
                <div className="not-found">
                    <h1>404</h1>
                    <h3>Page not found</h3>
                </div>
            );

    if (!LocalStorageService.getAccessToken()) {
        if (!isLoggingIn) {
            return (
                <div className="no-access">
                    <FeedbackIcon style={{ fontSize: 100,color:"#EC5D25" }} color="#EC5D25"/>
                    <IUITypography
                        variant="h4"
                        className="semibold iui__font--color-black900 iui__mb-5"
                    >
                        You don't have access to this page.
                    </IUITypography>
                    <IUITypography
                        variant="h5"
                        className="semibold iui__font--color-black900 iui__mb-5"
                    >
                        You'll be redirected to the home screen in <b>5 seconds</b>.
                    </IUITypography>
                </div>
            );
        }
    }
    return null;
};

export default memo(Page404);
