import { FETCH_AUTH_DETAILS, SET_AUTH_DETAILS, SAVE_CLIENT_ID, SAVE_CLIENT_ID_IN_AUTH, SAVE_CLIENT_PROFILE, FETCH_CLIENT_PROFILE, FETCH_CLIENT_SIDEBAR_MENUS, SET_CLIENT_SIDEBAR_MENUS, SET_CLIENT_USM_DETAILS } from '../../constants/common/Auth.types';

// importing action creator
import actionCreator from '../common/Util';

// import all urls
import { approvalUrls, commonUrls } from '../../../utils/urls/apiUrls';
import LocalStorageService from '../../../services/LocalStorage';


/** --- fetching user details --- */
export const fetchAuthDetails = (token, method, data) => {
	return actionCreator({
		method,
		data,
		// url: `${process.env.REACT_APP_BASE_URL}/api/v1/staff/details`,
		url: commonUrls.AUTH_DETAILS,
		onSuccess: setAuthDetails,
		accessToken: token,
		onFailure: () => console.log("Failed to fetch data"),
		label: FETCH_AUTH_DETAILS,
	});

	function setAuthDetails(res) {
		let __res = { ...res }
		__res.role = LocalStorageService.getRole();
		localStorage.setItem('language', __res.language)
		return {
			type: SET_AUTH_DETAILS,
			payload: __res
		}
	}

}

/** ---save client id--- */
export const saveClientId = (cid) => {
	return {
		type: SAVE_CLIENT_ID,
		payload: cid
	}
}

export const saveClientIdInAuth = (cid) => {
	return {
		type: SAVE_CLIENT_ID_IN_AUTH,
		payload: cid
	}
}


/** --- fetching client details for il_admin --- */
export const fetchClientDetails = (clientId) => {
	return actionCreator({
		url: approvalUrls.generateUrlWithClientId(clientId).GET_CLIENT_PROFILE,
		onSuccess: setClientProfile,
		onFailure: () => console.log("Failed to fetch data"),
		label: FETCH_CLIENT_PROFILE,
	});

	function setClientProfile(res) {
		return {
			type: SAVE_CLIENT_PROFILE,
			payload: res.result
		}
	}

}
/** --- fetching client menu options --- */
export const fetchClietSidebarMenus = (clientId) => {
	
	return actionCreator({
		url: `${commonUrls.MENU_LIST}/${clientId}`,
		onSuccess: setClientMenuList,
		onFailure: () => console.log("Failed to fetch data"),
		label: FETCH_CLIENT_SIDEBAR_MENUS,
	});

	function setClientMenuList(res) {
		return {
			type: SET_CLIENT_SIDEBAR_MENUS,
			payload: res
		}
	}

}
/** ---save client id--- */
export const saveClientUSM = (res) => {
	return {
		type: SET_CLIENT_USM_DETAILS,
		payload: res
	}
}