import {
    GET_HOTEL_LIST,
    GET_PREFERRED_HOTEL_LIST,
    GET_BLOCKED_HOTEL_LIST,
    FETCH_SELECTED_HOTEL_ID,
    FETCH_BLOCKED_HOTEL_ID,
} from "../../../../constants/IL_Admin/PolicyEditor/PreferredBlockedHotels/PreferredBlockedHotels.types";
import createReducer from "../../../../../utils/createReducer";
import { API_START, API_END } from "../../../../constants/common/Api.Types";

const initialState = {
    allHotelList: [],
    hasMore: true,
    hasMoreSelectedList: true,
    hasMoreBlockedList: true,
    preferredHotelsList: [],
    isListLoading: true,
    preferredApiResult: [],
    blockedHotelsList: [],
    blockedApiResult: [],
    pref_hotel_ids: [],
    blocked_hotel_ids: [],
};

export const reducerObj = {
    [API_START]: (prevState, payload) => {
        if (payload === FETCH_SELECTED_HOTEL_ID) {
            return {
                isListLoading: true,
            };
        } else if (payload === FETCH_BLOCKED_HOTEL_ID) {
            return {
                isListLoading: true,
            };
        }
    },
    [API_END]: (prevState, payload) => {
        if (payload === FETCH_SELECTED_HOTEL_ID) {
            return {
                isListLoading: false,
            };
        } else if (payload === FETCH_BLOCKED_HOTEL_ID) {
            return {
                isListLoading: false,
            };
        }
    },
    [GET_HOTEL_LIST]: (prevState, payload) => {
        let _tempArray = payload.reset ? [] : prevState.allHotelList;
        return {
            ...prevState,
            allHotelList: _tempArray.concat(payload.result),
            hasMore: payload.result.length !== 0 ? true : false,
        };
    },
    [GET_PREFERRED_HOTEL_LIST]: (prevState, payload) => {
        let _tempArray = payload.reset ? [] : prevState.preferredHotelsList;
        return {
            ...prevState,
            preferredApiResult: payload.result,
            preferredHotelsList: _tempArray.concat(payload.result),
            hasMoreSelectedList: payload.result.length !== 0 ? true : false,
            // isListLoading: false,
        };
    },
    [GET_BLOCKED_HOTEL_LIST]: (prevState, payload) => {
        let _tempArray = payload.reset ? [] : prevState.blockedHotelsList;
        return {
            ...prevState,
            blockedApiResult: payload.result,
            blockedHotelsList: _tempArray.concat(payload.result),
            hasMoreBlockedList: payload.result.length !== 0 ? true : false,
            // isListLoading: false,
        };
    },
    [FETCH_SELECTED_HOTEL_ID]: (prevState, payload) => {
        return {
            pref_hotel_ids: payload,
        };
    },
    [FETCH_BLOCKED_HOTEL_ID]: (prevState, payload) => {
        return {
            blocked_hotel_ids: payload,
        };
    },
};
const PreferredBlockedHotelsReducer = createReducer(initialState, reducerObj);

export default PreferredBlockedHotelsReducer;
