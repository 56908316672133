// import the all url related to headers

import { FETCH_HEADER_LINKS, SET_HEADER_LINKS } from '../../constants/common/Header.type';

import { RESET_STATE } from '../../constants/common/Api.Types';

// importing action creator
import actionCreator from '../common/Util';

import { commonUrls } from '../../../utils/urls/apiUrls';


/** ----- FETCHING THE HEADER DETAILS------ */
export const fetchHeaderLinks = (token, method, data, role) => {
	return actionCreator({
		method,
		data,
		url: `${commonUrls.HEADER}?role=${role}`,
		onSuccess: setHeadersLinks,
		accessToken: token,
		onFailure: () => console.log("Failed to fetch Data."),
		label: FETCH_HEADER_LINKS
	});

	function setHeadersLinks(res) {
		return {
			type: SET_HEADER_LINKS,
			payload: res
		}
	}
}